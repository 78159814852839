import React, {useState, useEffect} from 'react';
import '../../App.css';
import { Col, Container, Row, Image, Carousel, Card, Navbar, Table, Nav, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, useLocation, useHistory} from 'react-router-dom';
import '../../Componets/small-componets/Navbar/navbar.css'

export default function Safety() {


    const [waterLevel, setWaterLevel] = useState('loading...')

    //getting waterlevel data
    const url = 'https://waterservices.usgs.gov/nwis/iv/?format=json&sites=05570910&parameterCd=00065&siteStatus=all'

    useEffect(() => {
        async function getData() {
            const response = await fetch(url);
            const data = await response.json();
            const waterLevelStat = data.value.timeSeries[0].values[0].value[0].value
            setWaterLevel(waterLevelStat)
        }
        getData();
    },[])







    return (
        <div className='waiver'>
            <Container className='waiver-container-rivers' >
                <Navbar bg="light" variant="light">
                    {/* <Navbar.Brand href="#home">Navbar</Navbar.Brand> */}
                    <Nav className="mr-auto">
                        <Link className='link' active to='/about'>Trip Info</Link>
                        <Link className='link' to='/aboutriver'>About The River</Link>
                        <Link className='link' to='/wildlife'>Wildlife</Link>
                        <Link className='link' to='/safety'>Safety</Link>
                    </Nav>
                </Navbar>

                <br></br>
                <Row>
                    <Col style={{display:'flex', flexWrap:'wrap'}}  >
                    <div>
                        <h1>Safety</h1>
                    </div>
                    <div>
                        <br></br>
                        <hr></hr>
                        <p>No rentals shall occur in the event that the National Weather Service has issued a flood watch/warning for Champaign County</p>
                        <br></br>
                        <h3 style={{fontWeight:600}} >Current Sangamon Water Level: <a target="blank" href="https://waterdata.usgs.gov/nwis/uv?site_no=05572000" >{waterLevel} ft</a></h3>
                        <br></br>
                        <p>The <span style={{fontWeight:600}} >“Advanced”</span> route will be closed when water levels reach more than <span style={{fontWeight:600}} >10 feet</span></p>
                        <p>The <span style={{fontWeight:600}} >“Intermediate”</span> route will be closed when water levels reach more than <span style={{fontWeight:600}} >9 feet</span></p>
                        <p>A full refund will be issued if the water levels exceed these heights.</p>
                        <br></br>
                        <h5 style={{fontWeight:550, opacity: 0.8}}>Staff are required to have passed Paddlesports Safety Course</h5>
                        <h5 style={{fontWeight:550, opacity: 0.8}}>Also approved by the American Canoe Association</h5>
                    </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}
