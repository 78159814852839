import React,{useState, useEffect} from 'react'
import { Form, Col, Container, Row, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import renderCanoeOption from './renderCanoeOption';
import renderTandomOption from './renderTandemOption';
import renderKayaks from '../calendar/renderKayaks';
import renderKayaks2 from '../calendar/renderKayaksOtherList';
import sendingData from './sendingData';

function SelectionFrom({ kayaks, route, value, kayaksInStock, kayaksLeftList, setFormView, setFormData, bookingId, routesList, FromCalendarKayaksLeft, kayaksLeftOther, booked, booked2, booked3  }) {
    let history = useHistory();
    //form data info below
    const [tripName, SetTripName] = useState('no trip name');
    const [kayaksTaking, setKayaksTaking] = useState(0);
    const [startTime, setTime] = useState('10:00 AM');
    const [location, setLocation] = useState("I'll drive to the starting spot");
    const [otherLoaction, SetOtherLocation] = useState('not selected');
    const [email, setEmail] = useState('not selected');
    const [ifChecked, setChecked] = useState(false);
    const [alertCheck, setAlertCheck] = useState(false);
    const [canoe, setCanoe] = useState(2);
    const [sendingCanoeData, setSendingCanoeData] = useState(0);
    const [tandom, setTandom] = useState(true);
    const [sendingTandomData, setSendingTandomData] = useState(0);
    const [formStartTime, setFormStartTime] = useState('10:00 AM')
    const [phoneNumber, setPhoneNumber] = useState('111-111-1111')
    const [renderList1, setRenderList1] = useState(0)
    const [renderList2, setRenderList2] = useState(0)

    useEffect(() => {
        setCanoe(renderCanoeOption(booked, booked2, value, route, startTime, booked3))
        setTandom(renderTandomOption(booked, booked2, value, route, startTime, booked3))
    }, [startTime])

    useEffect(() => {
        
        setRenderList1(renderKayaks(value, kayaksInStock, booked, booked2, route, booked3))
        setRenderList2(renderKayaks2(value, kayaksInStock, booked, booked2, route, booked3))
        renderCanoeOption(booked, booked2, value, route, startTime, booked3)
        renderTandomOption(booked, booked2, value, route, startTime, booked3)
    }, [value])

    useEffect(() => {

        if(route === 'Salt Fork'){
            setLocation("I'll drive to Collins Pond")
        } else {
            setLocation("I'll drive to Lake of the Woods, Mahomet")
        }

    }, [])

    //rendering nonBooked days
    let renderKayaksOptions = []
    let renderKayaksOptions2 = []

    let renderCanoeOptionSelector = []

    console.log(renderList1 + ' renderList1 #')

    for (let i = 0; i < renderList1 + 1; i++){
        renderKayaksOptions.push(i)
    }
    for (let i = 0; i < renderList2 + 1; i++){
        renderKayaksOptions2.push(i)
    }
    for (let i = 0; i< canoe + 1; i++){
        renderCanoeOptionSelector.push(i)
    }

    let renderYaking = null
    if(startTime === '9:00 AM' || startTime === '10:00 AM'){

        renderYaking = (
            <>
                {renderKayaksOptions.map(x=> (<option value={x} >{x} kayak(s)</option>))}
            </>
        )
    } else {

        renderYaking = (
            <>
                {renderKayaksOptions2.map(x=> (<option value={x} >{x} kayak(s)</option>))}
            </>
        )
    }



    //rendering dynamic Tandom if the 1 tandom is availible - part 2
    let renderTandom = null
    if(tandom){
        renderTandom = (
            <>
                <option value={0} >0 tandem kayak</option>
                <option value={1} >1 tandem kayak ($45)</option>
            </>
        )
    }

  let random = Math.floor(Math.random() * Math.floor(10000000))

  useEffect(() => {
    //rendering dynmaic start time
    routesList.forEach(routeData => {
        if(routeData.name === route){
            setFormStartTime(routeData.time)
            setTime(routeData.time)
        }
    });

    if(route === 'no route'){
        history.push('/')
    }
 
  },[]);



  //render error if form isn't fully filled
  const continueButtonHandler = () => {
      if( email === '' || email === 'not selected'  || tripName === 'no trip name' || ifChecked === false){
          setAlertCheck(true)
      } else {
          setAlertCheck(false)
          let sendingDataRun = sendingData(location, startTime, otherLoaction);
          let booking = {
            route: route,
            name: tripName,
            numOfKayaks: kayaksTaking,
            numOfCanoe: sendingCanoeData,
            numOfTandom: sendingTandomData,
            time: startTime,
            pickUpLocation: sendingDataRun.location,
            date: value.format("MM/DD/YY"),
            email: email,
            timeBooked: moment().format('MMMM Do YYYY, h:mm:ss a'),
            bookingid: random,
            phoneNumber: phoneNumber
          }
          setFormData(booking)
          history.push(`/booking/${route}/form/pay`);
      }
  }


  let renderLocationAlert = null
  if((!(location === "I'll drive to Lake of the Woods, Mahomet")) ){
      if(!(location === "I'll drive to Lake of the Woods, Mahomet")){
        renderLocationAlert = (
            <Alert variant='primary' >
                <span style={{fontWeight: 700}} >Arrive 30 min before the start time below to get pick up!</span>
            </Alert>
        )
      }
  }







    return (
        <div>
            <Container fluid >
            <Row className="justify-content-md-center">
                <Form  className='form shadow' >
                    <div>
                        {alertCheck &&
                            <Alert  variant='warning'>
                                You need to fill out the whole form & agree
                            </Alert>
                        }
                    </div>
                    <div>
                        {renderLocationAlert}
                    </div>
                    <div>
                        <div className='info-form' >
                            Fill Out Info
                        </div>
                    </div>

                    <div>
                        <div className='header-form' >
                            <FontAwesomeIcon icon="map-marker-alt"  size="1x" /> {route} on {value.format('MM/DD/yy')}
                        </div>
                    </div>
                    <div className='form-body-top' >
                        <div>
                            <Form.Group as={Col} controlId="formGridname">
                                <Form.Control type="text" placeholder="Full Name" onChange={(e)=> {SetTripName(e.target.value)}} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group as={Col} controlId="formGridphone">
                                <Form.Control type="email" placeholder="Email" onChange={(e) => {setEmail(e.target.value)}} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group as={Col} controlId="formGridphone">
                                <Form.Control type="phone" placeholder="Phone" onChange={(e) => {setPhoneNumber(e.target.value)}} />
                            </Form.Group>
                        </div>
                    </div>
                    <div>
                        <div className='header-form' >
                            <Form.Label className='form-text'>Start Time</Form.Label>
                        </div>
                    </div>
                    <div  className='form-body-bottom'>
                        <div >
                            <Form.Control as="select" defaultValue="Choose..."  onChange={(e)=> {setTime(`${e.target.value}`); }}>
                                <option>{formStartTime}</option>
                                {(!(route === 'Sangamon')) &&
                                    <option>2:00 PM</option>
                                }
                            </Form.Control>
                        </div>
                        <div>
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Control as="select" defaultValue="Choose..."  onChange={(e)=> {setKayaksTaking(e.target.value)}}>
                                    {renderYaking}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div style={{display: 'flex'}} >
                            {canoe &&
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Control as="select" defaultValue="Choose..."  onChange={(e)=> {setSendingCanoeData(e.target.value)}}>
                                        {renderCanoeOptionSelector.map(x => (<option value={x} >{x} Canoe</option>))}
                                    </Form.Control>
                                </Form.Group>
                            }
                            {canoe === 0 && <div>: Canoes Available</div>}
                        </div>
                        <div>
                            {tandom &&
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Control as="select" defaultValue="Choose..."  onChange={(e)=> {setSendingTandomData(e.target.value)}}>
                                        {renderTandom}
                                    </Form.Control>
                                </Form.Group>
                            }
                        </div>
                    </div>
                    <div>
                        <div className='header-form' >
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">This is where you drive for the beginning of the kayak trip</Tooltip>}
                            >
                                {({ ref, ...triggerHandler }) => (
                                <div variant="light"
                                    {...triggerHandler}
                                    className="d-inline-flex align-items-center" ref={ref} 
                                >Starting Location - <a href='https://goo.gl/maps/w51zhW7qjGmafCKa7' target='blank' >Lake of the Woods, Izaak Walton Cabin (boat launch is a minute walk down the hill), Mahomet IL</a></div>
                                )}
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div>
                        <Form.Group id="formGridCheckbox" >
                            <a target='blank' href='https://kingfisherkayaking.com/waiver' >
                                <Form.Check onChange={(e) => {setChecked(e.target.checked)}}  type="checkbox" label="I have read the waiver, I legally agree to it." />
                            </a>
                        </Form.Group>
                    </div>
                    <div className='nav-buttons' >
                        <div className='arrow' onClick={continueButtonHandler}>
                            <span> Book </span><FontAwesomeIcon icon="arrow-right"  size="1x" /> 
                        </div>
                    </div>
                </Form>
            </Row>
        </Container>
        </div>
    )
}

export default SelectionFrom;