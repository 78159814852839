import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner} from 'react-bootstrap';
import Routes from '../../Componets/routes/routes';
import firebase from 'firebase';

export default function Routesbanner({setRouteSelected , waterLevelSetting, myRef, setRouteCost, routesList, setCanoeCost}) {
  
    const [bannerColor, setBannerColor] = useState('blue')
    const [hide, setBanner] =useState(true)
    const [bannerText, setBannerText] = useState('Loading...')
    const [loading, setLoading] = useState(false)
    const db = firebase.firestore();

    useEffect(() => {
        getFirebaseData()
    }, [])
    
    const getFirebaseData = () => {
        db.collection('banner').get().then((querySnapshot) => {

            querySnapshot.forEach((doc) => {
              setBanner(doc.data().hide)
              setBannerColor(doc.data().color)
              setBannerText(doc.data().text)
            })
        })
    }
  
    const publishToFirebase = () => {
        setLoading(!loading)
        setTimeout(() => {
            setLoading(false)
        }, "1000")

        db.collection("banner").doc("Y3QzQMmYFE1NzltuoVoW").update({
            color: bannerColor,
            text: bannerText,
            hide: hide
          }).then(function() {
            console.log("updated banner");
          });          
    }

    return (
        <div>
            <div>
                <br></br>
                <br></br>
                <div style={{fontSize: '20px', color: 'white', backgroundColor: `${bannerColor}`, fontWeight: 600, textAlign: 'center', padding: '10px'}} >
                    <input type='text' onChange={(e)=>setBannerText(e.target.value)} value={bannerText} />{" "}
                    <FontAwesomeIcon icon="brush"  size="1x" />{" "}
                    <input type='text' onChange={(e)=> setBannerColor(e.target.value)} value={bannerColor} />
                    <Button onClick={()=> setBanner(!hide)} >{hide ? 'hide banner' : 'show banner'}</Button>
                    {loading
                        ? <Spinner animation="border" />
                        : <Button onClick={()=> publishToFirebase()} variant="danger">Publish</Button>
                    }
                </div>
            </div>

{/* Go to newRouteCard.js in Componets in Routes to edit route admin functions */}

            <Routes
                setRoute={setRouteSelected}
                waterLevel={waterLevelSetting}
                setRouteSelected={setRouteSelected}
                routesList={routesList}
                setCanoeCost={setCanoeCost}
                setRouteCost={setRouteCost} />
        </div>
  )
}
