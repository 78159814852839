import React, {useState, useEffect} from 'react';

import Password from './password';
import Categories from './categories';

export default function Admin({ booked3, kayakStock, kayaksLeftOther,closed, booked, booked2, bookingId, value, setKayaksLeft, routeSelected, back, calendarData, kayaksInStock, setKayaksLeftOther, form, setValue, kayaksLeft, setRouteSelected , waterLevelSetting, myRef, setRouteCost, routesList, setCanoeCost}){

    const [password, setPassword] = useState(false)
    const [text, setText] = useState('')


    useEffect(() => {
        window.scrollTo(0, 0) 
    },[] )

    const passwordCode = `bigblue`

    const submittPass = (e) => {
        e.preventDefault()

        if(text === passwordCode){
            setPassword(true)
        }
    }

    let passingData = {
        booked3,
        kayakStock,
        kayaksLeftOther,
        booked,
        booked2,
        bookingId,
        value,
        setKayaksLeft,
        routeSelected,
        back,
        calendarData,
        kayaksInStock,
        setKayaksLeftOther,
        form,
        setValue,
        kayaksLeft,
        closed
    }

    let renderData = null;

    if(password){
        renderData = (
            <>
                <Categories 
                    passingData={passingData}
                    setRouteSelected={setRouteSelected} 
                    waterLevelSetting={waterLevelSetting} 
                    myRef={myRef}
                    setRouteCost={setRouteCost} 
                    routesList={routesList}
                    setCanoeCost={setCanoeCost} />
            </>
        )
    } else {
        renderData = (
            <>
                <Password  submittPass={submittPass} setText={setText} />
            </>
        )
    }


  return (
      <div >
        {renderData}
      </div>
  )
}