import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import NewRoute from './newRouteCard';


function Routes({ setRoute, waterLevel, routesList, setRouteCost, setCanoeCost }) {


    const history = useHistory();
        
 
    
    return (
        <div>
        <Container className='myRoutes'>
                <Row >
                    <Col>
                        <h1 className='heading' style={{marginTop: '20px'}} >BOOK ROUTE</h1>
                    </Col>
                </Row>
                <Row className='route-list' >
                    <ul  >
                        {routesList.map(route =>{
                            return <NewRoute 
                                setRouteCost={setRouteCost} 
                                setCanoeCost={setCanoeCost}  
                                waterLevelSetting={waterLevel} 
                                setRoute={setRoute} 
                                waterLevel={route.data.site} 
                                route={route} />
                        })}
                    </ul>
                </Row>
        </Container>
        </div>
    )
}

export default Routes;