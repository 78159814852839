import React from 'react';
import '../App.css';


export default function SuccessPage({formData}){

  



  //whats rendered to the DOM
  return (
      <div className='RoutesList' >
        <div className='success' >
          Success! Thank You, an email has been sent to {formData.email}
        </div>
      </div>
  )
}