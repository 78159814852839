import React from 'react'
import { Navbar, Nav } from 'react-bootstrap';
import {Link, useLocation, useHistory} from 'react-router-dom';
import './navbar.css'
import newLogo from '../../../logo2022.png';

function MyNavbar(props) {
    
    let location = useLocation();
    let history = useHistory();
    function handleClick() {
        history.push("/");
    }

    //path route for book    
    const bookHandler = () => {
        if(location.pathname === '/'){
            props.executeScroll()
        } else {
            handleClick()
            setTimeout(function(){props.executeScroll()}, 100);
        }
    }



    
    return (
        <div>
            <Navbar className='my-nav' bg="light" expand="lg">
                <Link to='/' className='no-hover' >
                    <div className='no-hover' style={{display: 'flex'}} >
                    <div className='no-hover' style={{marginRight: '10px'}} >
                        <img
                            alt=""
                            src={newLogo}
                            width="50"
                            height="50"
                            className="d-inline-block align-top"
                        />
                    </div>
                    <div className='no-hover' style={{marginTop: '10px', textDecoration: 'none'}} >
                        <span className='brand-title no-hover'  >Kingfisher Kayaking</span>
                    </div>
                    </div>
                </Link>   
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Link className='link' to='/about' >
                            Rivers
                        </Link>
                        <Link className='link' onClick={bookHandler} >
                            Book
                        </Link>
                        <Link className='link' to='/waiver' >
                            Waiver
                        </Link>
                        <Link className='link' to='/faq' >
                            FAQ
                        </Link>
                        <Link className='link' to='/involved' >
                            Donate
                        </Link>
                        <Link className='link' onClick={()=> window.open("https://www.bonfire.com/kingfisher-kayaking/", "_blank")} >
                            Store
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default MyNavbar;