import React from 'react'
import { Row, Form, Col, Container, Button } from 'react-bootstrap';

export default function Password({submittPass, setText}) {
    return (
        <div style={{backgroundColor: 'green'}} >
            <Container fluid >
            <div style={{display: 'flex', justifyContent: 'center', padding: '180px'}} >
                <Form onSubmit={submittPass}  >
                    <Form.Group as={Row} >
                        <div>
                        <h2 style={{fontWeight: 600, paddingRight: '7px', color: 'white'}} >Password</h2>
                        </div>
                        <div style={{display: 'flex', flexWrap: 'wrap'}} >
                            <div>
                                <Form.Control style={{width: '200px', marginRight: '7px', marginBottom:'7px'}} onChange={(e)=> setText(e.target.value)} type="password" placeholder="Password" />
                            </div>
                            <div>
                                <Button onClick={submittPass} variant="primary">Sign In</Button>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </div>
            </Container>
        </div>
    )
}
