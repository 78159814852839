import React, {useState, useEffect} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css'; // import css


//components
import MyJumbotron from '../Componets/small-componets/Jumbotron/jumbotron';
import Routes from '../Componets/routes/routes';
import Video from '../Componets/small-componets/video/video';
import { Alert } from 'react-bootstrap';






export default function HomePage({ setRouteSelected , waterLevelSetting, myRef, setRouteCost, routesList, setCanoeCost}){






  //whats rendered to the DOM
  return (
      <div >
          <Video  />
          <MyJumbotron  />
          <div ref={myRef} className='RoutesList' id='routes' >
            <div style={{textAlign: 'center', color: 'white',  display: 'flex', justifyContent: 'center'}} >
            </div>
            <Routes
              setRoute={setRouteSelected}
              waterLevel={waterLevelSetting}
              setRouteSelected={setRouteSelected}
              routesList={routesList}
              setCanoeCost={setCanoeCost}
              setRouteCost={setRouteCost} />
            <div style={{textAlign: 'center', color: 'white',  display: 'flex', justifyContent: 'center', cursor: 'pointer'}} onClick={()=> window.open("https://firebasestorage.googleapis.com/v0/b/kingfisher-kayaking-website.appspot.com/o/Kingfisher%20Kayaking%20Field%20Guide.pdf?alt=media&token=9860f8f1-eb2b-45a1-bb57-cfaef02a7d1c", "_blank")} >
              <div></div>
              <div style={{backgroundColor: 'gray', borderRadius: '10px', padding: '5px', opacity: .8}} >
                <h6><span style={{fontWeight: 700}} >Kingfisher Field Guide <img width='50px' src="https://firebasestorage.googleapis.com/v0/b/kingfisher-kayaking-website.appspot.com/o/squirrel%20(1).png?alt=media&token=a8c0b49d-63fc-49f2-9f4c-3883d6718ea2" /></span></h6>

              </div>
            </div>
            <br></br>  
            <div style={{textAlign: 'center', color: 'white',  display: 'flex', justifyContent: 'center'}} >
              <div></div>
              <div style={{backgroundColor: 'gray', borderRadius: '10px', padding: '5px', opacity: .8}} >
                <h6><span style={{fontWeight: 700}} >Beginner</span>: slow waters and no log jams and few obstacles</h6>
                <h6><span style={{fontWeight: 700}}>Intermediate</span>: faster waters and several obstacles</h6>
                <h6><span style={{fontWeight: 700}}>Advanced</span>: faster waters, log jams and more obstacles, and longer trips</h6>
              </div>
            </div>
          </div>
      </div>
  )
}