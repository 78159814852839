import React, { useEffect, useState } from 'react';
import { useHistory, useLocation} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button} from 'react-bootstrap';
import './newStyle.css';
import firebase from 'firebase';
import {storage} from '../../index'


const Route = ({setRoute, waterLevelSetting, setRouteCost, route, setCanoeCost, waterLevel}) => {

    const [waterLevelAPI, setWaterLevel] = useState(null);
    const [admin, setAdmin] = useState(false)
    const [routeEdits, setRouteEdits] = useState({
        name: ''
    });
    const [imgfile, setImgFile] = useState(null)
    //routing
    const history = useHistory();
    let location = useLocation();
    const db = firebase.firestore();
    //getting waterlevel data
    const url = `https://waterservices.usgs.gov/nwis/iv/?format=json&sites=0${waterLevel}&parameterCd=00065&siteStatus=all`

    useEffect(() => {
        async function getData() {
            const response = await fetch(url);
            const data = await response.json();
            const waterLevelStat = data.value.timeSeries[0].values[0].value[0].value
            setWaterLevel(waterLevelStat)
        }
        getData();

        if(location.pathname === '/admin'){
            setAdmin(true)
            setRouteEdits({
                name: route.data.name,
                difficulty: route.data.difficulty,
                distance: route.data.distance,
                duration: route.data.duration,
                subtitle: route.data.subtitle,
                time: route.data.time,
                link: route.data.link,
                riverLevelFloor: route.data.riverLevelFloor,
                riverLevelRoof: route.data.riverLevelRoof,
                hide: route.data.hide,
                cost: route.data.cost,
                costCanoe: route.data.costCanoe,
                site: route.data.site,
                img: route.data.img
            })
        }else{ setAdmin(false)}

    },[])

    const handleChange = e => {
        const { name, value } = e.target;
        setRouteEdits(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleClick = () => {
        setRouteEdits(prevState => ({
            ...prevState,
            hide: !route.data.hide
        }));
    }

    const replaceImg = (e) => {
        setImgFile(e.target.files[0])
    }

    const updateRoute = () => {

        const uploadTask = storage.ref(`images/${imgfile.name}`).put(imgfile);
        uploadTask.on(
            "state_changed",
            snapshot => {},
            error => {
                console.log(error);
            },
            () => {
                storage.ref("images").child(imgfile.name).getDownloadURL().then(url => {
                        console.log('uploaded img', url)
                        setRouteEdits(prevState => ({
                            ...prevState,
                            img: url
                        }))
                        pushData()
                    });
            }
        ) 
    }
    const pushData = () => {
        setTimeout(() => {
            db.collection("routes").doc(`${route.id}`).update({
                name: route.data.name,
                difficulty: route.data.difficulty,
                distance: route.data.distance,
                duration: route.data.duration,
                subtitle: route.data.subtitle,
                time: route.data.time,
                link: route.data.link,
                riverLevelFloor: route.data.riverLevelFloor,
                riverLevelRoof: route.data.riverLevelRoof,
                hide: route.data.hide,
                cost: route.data.cost,
                costCanoe: route.data.costCanoe,
                site: route.data.site,
                img: route.data.img
            }).then(function() {
                console.log("updated route");
            });
        }, "1000")
    }


    //rendering waterlevel data and style
    let clickFunction = null
    let buttonName = 'Book'
    let redAlert = null
    if(waterLevelAPI === null) {
        buttonName = 'Book'
        redAlert = 'content'
        clickFunction = (
            () => {history.push(`/booking/${route.data.name}`); setRoute(route.data.name); setRouteCost(route.data.cost); setCanoeCost(route.data.costCanoe);  }
        )
    } else {
        if(waterLevelAPI < route.data.riverLevelFloor) {
            buttonName = 'Water is too low'
            redAlert = 'red content '
            clickFunction = (null)
        } else if (waterLevelAPI > route.data.riverLevelRoof){
            buttonName = 'Water is too high'
            redAlert = 'red content '
            clickFunction = (null)
        } else {
            redAlert = 'content'
            clickFunction = (
                () => {history.push(`/booking/${route.data.name}`); setRoute(route.data.name); setRouteCost(route.data.cost);setCanoeCost(route.data.costCanoe);   }
            )
        }
    }

    if(waterLevel === 0 ){
        //for special events
        buttonName = 'Free Day';
        redAlert = 'content'
        clickFunction = (
            ()=> {window.location = `${route.data.link}`}
        )
    }

    if(admin){clickFunction=null}


  return (
    <>
    {(route.data.hide && !admin) === false &&
        <div>
            <li  className="booking-card" style={{backgroundImage: `url(${route.data.img})`}} >
                <div onClick={clickFunction} className="book-container">
                <div className={redAlert}>
                    <button  className="btn">
                        {buttonName}
                    </button>
                </div>
                </div>
                <div className="informations-container" onClick={clickFunction}>
                <h2 className="title"><FontAwesomeIcon icon="map-marker-alt" onClick={clickFunction}  size="1x" /> 
                    {admin
                        ? <input type="text" name='name' onChange={handleChange} value={routeEdits.name}/>
                        : <>{route.data.name}</>
                    }
                </h2>
                <p className="sub-title">
                    <span>                    
                        {admin
                            ? <input type="text" onChange={handleChange} name='difficulty' value={routeEdits.difficulty}/>
                            : <>{route.data.difficulty}</>
                        }
                    </span> | <span>
                        {admin
                            ? <input type="number" onChange={handleChange} name='distance' value={routeEdits.distance}/>
                            : <>{route.data.distance}</>
                        }
                    </span> miles | <FontAwesomeIcon icon="clock"  size="1x" /> <span>
                        {admin
                            ? <input type="text" name='duration' onChange={handleChange} value={routeEdits.duration}/>
                            : <>{route.data.duration}</>
                        }
                    </span> hrs
                </p>
                <p className="sub-title" style={{fontSize: '12px'}} >
                    {admin
                        ? <input type="text" name='subtitle' onChange={handleChange} value={routeEdits.subtitle}/>
                        : <>{route.data.subtitle}</>
                    }
                </p>
                <div className="more-information">


                    <div className="info-and-date-container">
                    <div onClick={clickFunction} className="box info">
                        <Table striped bordered style={{color: '#ec992c'}} size="sm">
                            <thead>
                                <tr>
                                <th>Kayak</th>
                                <th>Tandem Kayak</th>
                                <th>Canoe</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><FontAwesomeIcon icon="dollar-sign"  size="1x" /> {route.data.cost}</td>
                                <td><FontAwesomeIcon icon="dollar-sign"  size="1x" /> {(route.data.site===0) ? '0' : '45'}&#42;</td>
                                <td><FontAwesomeIcon icon="dollar-sign"  size="1x" /> {route.data.costCanoe}&#42;</td>
                                </tr>
                            </tbody>
                        </Table>

                        <Table striped bordered style={{color: '#ec992c'}} size="sm">
                            <thead>
                                <tr>
                                <th colSpan={2} >Start Times</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>
                                    {admin
                                        ? <input type="text" name='time' onChange={handleChange} value={routeEdits.time}/>
                                        : <>{route.data.time}</>
                                    }
                                </td>                      
                                {(route.data.name != 'Sangamon') &&
                                <td>2:00 PM</td>
                                }  
                                </tr>
                            </tbody>
                        </Table>

                        
                        {/* <h5><FontAwesomeIcon icon="water"  size="1x" /> {waterLevelAPI} ft </h5> */}
                    </div>
                    </div>
                    
                    {route.data.link === 'none' ?
                        <div onClick={() => history.push('/about')} style={{height: '50px', cursor: 'pointer'}} >
                        <p onClick={() => history.push('/about')} className="disclaimer">&#42; We have 2 canoe and 1 tandem kayak, and the canoes is only on available on weekends</p>
                        <p className="disclaimer" style={{fontSize: '11px'}} >We offer special discounts to groups - Contact Us</p>
                        </div>
                        : 
                        <div><br></br><p>We will be kayaking and picking up trash. We have limited spots so please don't wait on letting us know.</p></div>
                        
                    }



                    </div>
                </div>
            </li>

        {admin &&
            <div>
                <Button onClick={handleClick} name='hide' >{routeEdits.hide ? 'show route' : 'hide route'}</Button>
                <div>impossible to book outside range</div>
                <div>
                    River Level Roof(ft)<input type="text" onChange={handleChange} name='riverLevelRoof' value={routeEdits.riverLevelRoof} />
                </div>
                <div>
                    River Level Floor(ft)<input type="text" onChange={handleChange} name='riverLevelFloor' value={routeEdits.riverLevelFloor} />
                </div>
                {(waterLevel === 0) &&
                    <div>
                        Survay Link for Volun. List
                        <input onChange={handleChange} type='text' name="link" value={routeEdits.link} />
                    </div>
                }
                <div>Replace image</div>
                <input type='file' onChange={replaceImg} />
                <div>
                    <Button onClick={() => updateRoute(route)} variant="danger">Save</Button>
                </div>
            </div>
        }

    </div>
    }
    </>

  );
};

export default Route;