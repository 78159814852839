export default function isBookingOnSangamon(booked, otherCalendarDataList, value) {

    let isTrue = false    


    booked.forEach(booking => {
        if (value.isSame(booking.date, 'day') ) {
            isTrue = true

        }
    });
        

    otherCalendarDataList.forEach(booking => {
        if (value.isSame(booking.date, 'day')) {
            isTrue = true

        }
    });

    return isTrue

}