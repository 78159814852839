import React, {useState, useEffect} from 'react'
import { Button, Container, Modal, Table } from 'react-bootstrap';
import firebase from 'firebase';
import moment from 'moment'


export default function SearchDatabase({booked, booked2}) {

    const [searchText, setSearchText] = useState('*');
    const [show, setShow] = useState(false);
    const [foundArray, setFoundArray] = useState(['']);
    const db = firebase.firestore();
    const [show2, setShow2] = useState(false);
    const [date, setDate] = useState('')
    const [k, setK] = useState('')
    const [c,setC] = useState('')
    const [t,setT] = useState('')
    const [l, setL] = useState('')
    const [r, setR] = useState('')

 



    useEffect(() => {
        


        const filterBookings = () => {

            let found = []

            const filtering = booked.filter( function( booking, index){
                const isNameMatch = booking.name.toLowerCase().includes(searchText)
                return isNameMatch        
            })   
            filtering.forEach(booking => {
                found.push(booking)
            });
            const filtering2 = booked2.filter( function( booking, index){
                const isNameMatch = booking.name.toLowerCase().includes(searchText)
                return isNameMatch        
            })   
            filtering2.forEach(booking => {
                found.push(booking)
            });
            setFoundArray(found)
        } 
        filterBookings();
    }, [searchText])



    const deleteHandler = () => {
        setShow(true)
    }
    


    const removeFromDatabase = (database) => {
        setShow(false)


        db.collection(`${database.ref}`).doc(`${database.id}`).delete().then(() => {
            alert('you deleted it from the database, to see the results, refresh the page')
        }).catch((error) => {
            alert('error, let James know you got an error')
        });
    }






    const updateData = (database) => {

        console.log('was clicked', k,c,t)

        let sendingC = null
        if(c === ''){sendingC = database.numOfCanoe} else{sendingC=c}
        let sk = null
        if(k===''){sk = database.numOfKayaks}else{sk=k}
        let st = null
        if(t===''){st=database.numOfTandom}else{st=t}
        let sl = null
        let time = null
        if(l===''){sl=database.pickUpLocation}else{sl=l}
        let sr = null
        if(r===''){sr=database.route}else if (r === 'sangHalfMorn'){
            sr = 'Sangamon (Half Route)';
            time="9:00 AM";
            console.log('option 1')
        } else if (r === 'sangHalfAft'){
            sr = 'Sangamon (Half Route)';
            time="2:00 PM";
            console.log('option 2')
        } else if (r==='Sangamon'){
            sr = 'Sangamon';
            time="9:00 AM";
            console.log('option 3')
        }
        let sd = null
        if(date===''){sd=database.date}else{sd=date}
    
        console.log(sendingC, sk, st)


        db.collection(`${database.database.ref}`).doc(`${database.database.id}`).update({
            numOfCanoe: sendingC,
            numOfKayaks: sk,
            numOfTandom: st,
            pickUpLocation: sl,
            route: sr,
            date: moment(sd).format('MM/DD/YY'),
            time: time
        }).then(function() {
            alert('changes were made, refresh the page and check' )
            console.log(sendingC, sk, st, sl, sr, moment(sd).format('MM/DD/YY'), time)
            setShow2(false)
        });
    }

    return (
        <Container  >
            <div style={{display:'flex', justifyContent: 'center'}}>
                <h3>SEarch Database for Name</h3>
                <input placeholder='type here' onChange={ (e) => setSearchText(e.target.value)} />
                <br></br>
            </div>




            <div style={{textAlign:'left'}} >
            {foundArray.map((booking) => 
                <div style={{border: '2px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column'}} >
                    <Modal show={show} onHide={()=> setShow(false)}>
                        <Modal.Header closeButton>
                        <Modal.Title>Deleting...</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure? Can't undo this</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={()=> setShow(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={()=> removeFromDatabase(booking.database)}>
                            Delete
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={show2} onHide={()=> setShow2(false)}>
                        <Modal.Header closeButton>
                        <Modal.Title>Edit the info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            
                            <div>
                                date: <input onChange={(e)=> setDate(e.target.value)} type='date' />
                            </div>
                            <div>
                                Number of Kayaks: <input  onChange={(e)=> setK(e.target.value)} type='number' />
                            </div>
                            <div>
                                Number of Canoe: <input onChange={(e)=> setC(e.target.value)} type='number' />
                            </div>
                            <div>
                                Number of Tandem: <input onChange={(e)=> setT(e.target.value)} type='number' />
                            </div>
                            <div>
                                pick-up: <input onChange={(e)=> setL(e.target.value)} type='text' />
                            </div>
                            <div>
                                route: <select onChange={(e)=> setR(e.target.value)} >
                                            <option value="Sangamon">Sangamon</option>
                                            <option value="sangHalfMorn">Sangamon Half -- morning</option>
                                            <option value="sangHalfAft">Sangamon Half -- afternoon</option>
                                        </select>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={()=> setShow2(false)}>
                            Cancel
                        </Button>
                        <Button variant="warning" onClick={()=> updateData(booking)}>
                            Update Info
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    <div ><span style={{fontWeight: 600, paddingRight: '10px'}} >Name:</span>{booking.name}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Phone:</span>{booking.phone}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Email:</span>{booking.email}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Date:</span>{booking.date}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Time:</span>{booking.time}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Loc:</span>{booking.pickUpLocation}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Route:</span>{booking.route}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Kayak:</span>{booking.numOfKayaks}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Canoe:</span>{booking.numOfCanoe}</div>
                    <div><span style={{fontWeight: 600, paddingRight: '10px'}} >Tandem:</span>{booking.numOfTandom}</div>
                    <div><Button onClick={() =>setShow2(true)} style={{marginRight: '100px'}} variant="warning">Edit</Button><Button onClick={()=>deleteHandler()} variant="danger" >Delete</Button></div>
                </div>
            )}
            </div>
        </Container>
    )
}
