import React, {useState, useEffect} from 'react'
import firebase from 'firebase';
import { Button, Spinner } from 'react-bootstrap';
import {useLocation} from 'react-router-dom'


export default function Close({value}) {

    const db = firebase.firestore();
    let location = useLocation();
    const [date, setDate] = useState('');
    const [admin, setAdmin] = useState(true)
    const [loading, setLoading] = useState(false)
    const [datesClosed, setClosedDates] = useState([])


    useEffect(()=> {
        if(location.pathname != '/admin'){
            setAdmin(false)
        }

        setDate(value.format('L'))

    }, [value])

    useEffect(() => {
        getFirebaseData()
    }, [])
    
    const getFirebaseData = () => {
        db.collection('closed').get().then((querySnapshot) => {

            querySnapshot.forEach((doc) => {
              const a = {
                date: doc.data().date,
                id: doc.id
              }
              setClosedDates(datesClosed => [...datesClosed, a])
            })
        })
    }



    const closeForTheDay = (date) => {
        console.log('close '+ date.format('L'))
        const dateSelected ={date: date.format('L')}
        // setLoading(true);
        let match = false
        let matchId = ''
        console.log(datesClosed)
        
        const checkDate = () => {
            datesClosed.forEach(data => {
                if(data.date === dateSelected.date){
                    console.log('match'+data.id)
                    match = true
                    matchId = data.id
                }
            });
        }
        checkDate()

        if(match){
            try {
                db.collection('closed').doc(`${matchId}`).delete().then(() => {
                    console.log('open again')
                }).catch((error) => {
                    alert('error1, let James know you got an error')
                });
                setLoading(false)
                alert('reload page to see results')
            } catch (err) {
                alert('error2, let James know you got an error')
            }
        } else {
            try {
                db.collection('closed').add(dateSelected).then(
                    console.log('data was added to database')
                )
                setLoading(false)
                alert('reload page to see results')
            } catch (err) {
                alert('error3, let James know you got an error')
            }
        }
    }


    return (
        <div>
            { admin &&       
                <div>    
                    <div>
                        {loading
                            ? <Spinner animation="border" />
                            : <Button onClick={()=> closeForTheDay(value)} variant="danger" >Close for the Day</Button>
                        }
                    </div>
                    <div>
                        Day Selected: {date}
                    </div>
                </div>
            }
        </div>
    )
}
