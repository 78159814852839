export default function twoKayaksLeft(day, booked,kayaksInStock) {

    let kayakCount = []
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let classes = null
    booked.forEach(bookedData => {
        if (day.isSame(bookedData.date, 'day')) {
            let parsedKayaks = JSON.parse(bookedData.numOfKayaks)
            kayakCount.push(parsedKayaks)

            if (kayakCount.length > 0){
                let kayaksBookedTotal = kayakCount.reduce(reducer)
                
                if(((kayaksBookedTotal + 2) === kayaksInStock) || ((kayaksBookedTotal + 3) === kayaksInStock) || ((kayaksBookedTotal + 1) === kayaksInStock)) {
                    classes = bookedData.date
                }
            }
        }
    })
    return day.isSame(classes, 'day')
}