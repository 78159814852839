import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TheCalendar from '../../Componets/calendar/index'

export default function Calendar({setTurnstop, passingData}) {
    return (
        <div style={{backgroundColor: '#56899e', paddingTop: '50px', paddingBottom: '50px'}} >

            <div style={{display: 'flex', justifyContent: 'center', color: 'white'}} >
                <FontAwesomeIcon onClick={()=>setTurnstop(1)} icon="arrow-left"  size="2x" style={{marginRight: '10px'}} />
                <h3>Calendar</h3>
            </div>
            <TheCalendar 
                kayaksLeft={passingData.kayaksLeft}
                kayaksLeftOther={passingData.kayaksLeftOther}
                otherCalendarDataList={passingData.booked2}
                setKayaksLeft={passingData.setKayaksLeft}
                setKayaksLeftOther={passingData.setKayaksLeftOther}
                value={passingData.value} 
                onChange={passingData.setValue} 
                routeSelected='Sangamon (Half Route)'
                back={passingData.back} 
                booked={passingData.booked}
                kayaksInStock={passingData.kayaksInStock} 
                form={passingData.form}
                booked3={passingData.booked3}
                closed={passingData.closed}
            />
            <TheCalendar 
                kayaksLeft={passingData.kayaksLeft}
                kayaksLeftOther={passingData.kayaksLeftOther}
                otherCalendarDataList={passingData.booked2}
                setKayaksLeft={passingData.setKayaksLeft}
                setKayaksLeftOther={passingData.setKayaksLeftOther}
                value={passingData.value} 
                onChange={passingData.setValue} 
                routeSelected='Sangamon'
                back={passingData.back} 
                booked={passingData.booked}
                kayaksInStock={passingData.kayaksInStock} 
                form={passingData.form}
                booked3={passingData.booked3}
                closed={passingData.closed}
            />
        </div>
    )
}
