import React, {useState, useEffect} from 'react';
import '../App.css';
import Calendar from '../Componets/calendar/index';

export default function BookingPage({ routeSelected,setRouteSelected, setKayaksLeft, value, kayaksLeft, setValue, back,closed, booked, kayaksInStock, booked2, booked3, form, setKayaksLeftOther, kayaksLeftOther}){

  useEffect(() => {
    window.scrollTo(0, 0) 
  }, [])

  return (
      <div className='RoutesList'>
        <Calendar 
          setRouteSelected={setRouteSelected}
          kayaksLeft={kayaksLeft}
          kayaksLeftOther={kayaksLeftOther}
          otherCalendarDataList={booked2}
          booked3={booked3}
          setKayaksLeft={setKayaksLeft}
          setKayaksLeftOther={setKayaksLeftOther}
          value={value} 
          onChange={setValue} 
          routeSelected={routeSelected}
          back={back} 
          booked={booked}
          kayaksInStock={kayaksInStock} 
          form={form}
          closed={closed}
           />
      </div>
  )
}