import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import firebase from 'firebase';
import { Button, Container, Modal } from 'react-bootstrap';
import moment from 'moment'
import Close from './close'

export default function AdminCal({otherCalendarDataList, value, routeSelected, booked, booked3}) {
    
    let location = useLocation();
    const db = firebase.firestore();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [admin, setAdmin] = useState(false)
    const [dayData, setDayData] = useState([])

    const [date, setDate] = useState('')
    const [k, setK] = useState('')
    const [c,setC] = useState('')
    const [t,setT] = useState('')
    const [l, setL] = useState('')
    const [r, setR] = useState('')


    useEffect(() => {
        if(location.pathname === '/admin'){
            setDayData([])
            setAdmin(true)

            if(routeSelected === 'Sangamon'){
                booked.forEach(booking => {
                    if(value.isSame(booking.date, 'day')){
                        setDayData(dayData => [...dayData, booking])
                    }
                });
            } else if(routeSelected === 'Sangamon (Half Route)') {
                otherCalendarDataList.forEach(booking => {
                    if(value.isSame(booking.date, 'day')){
                        setDayData(dayData => [...dayData, booking])
                    }
                })
            } else {
                booked3.forEach(booking => {
                    if(value.isSame(booking.date, 'day')){
                        setDayData(dayData => [...dayData, booking])
                    }
                })
            }
        }else{ setAdmin(false)}

    }, [value])






    const updateData = (database) => {

        console.log('was clicked', k,c,t)

        let sendingC = null
        if(c === ''){sendingC = database.numOfCanoe} else{sendingC=c}
        let sk = null
        if(k===''){sk = database.numOfKayaks}else{sk=k}
        let st = null
        if(t===''){st=database.numOfTandom}else{st=t}
        let sl = null
        let time = null
        if(l===''){sl=database.pickUpLocation}else{sl=l}
        let sr = null
        if(r===''){sr=database.route}else if (r === 'sangHalfMorn'){
            sr = 'Sangamon (Half Route)';
            time="9:00 AM";
            console.log('option 1')
        } else if (r === 'sangHalfAft'){
            sr = 'Sangamon (Half Route)';
            time="2:00 PM";
            console.log('option 2')
        } else if (r==='Sangamon'){
            sr = 'Sangamon';
            time="9:00 AM";
            console.log('option 3')
        }
        let sd = null
        if(date===''){sd=database.date}else{sd=date}
    
        console.log(sendingC, sk, st)


        db.collection(`${database.database.ref}`).doc(`${database.database.id}`).update({
            numOfCanoe: sendingC,
            numOfKayaks: sk,
            numOfTandom: st,
            pickUpLocation: sl,
            route: sr,
            date: moment(sd).format('MM/DD/YY'),
            time: time
        }).then(function() {
            alert('changes were made, refresh the page and check' )
            console.log(sendingC, sk, st, sl, sr, moment(sd).format('MM/DD/YY'), time)
            setShow2(false)
        });



    }

    
    const removeFromDatabase = (database) => {
        setShow(false)

        db.collection(`${database.ref}`).doc(`${database.id}`).delete().then(() => {
            alert('you deleted it from the database, to see the results, refresh the page')
        }).catch((error) => {
            alert('error, let James know you got an error')
        });
    }


    return (
        <div>
            {admin &&
            
            <div className='arrow'  >
                            <h3>Booking Data</h3>
                            {dayData.map(data => {

                                let location = data.pickUpLocation
                                let time = data.time

                                if(data.pickUpLocation === "I'll drive to River Bend Forest Preserve"){
                                    location = 'Mahomet Canoe Launch Facility at Greenway parking lot https://goo.gl/maps/nTiiiCBAEiZJ5GAUA'
                                } else {
                                    if(data.time === '9:00 AM'){time = '8:30 AM'} else {time = '1:30 PM'}
                                }

                                return <div>
                                    <Modal show={show} onHide={()=> setShow(false)}>
                                        <Modal.Header closeButton>
                                        <Modal.Title>Deleting...</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure? Can't undo this</Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="secondary" onClick={()=> setShow(false)}>
                                            Cancel
                                        </Button>
                                        <Button variant="danger" onClick={()=> removeFromDatabase(data.database)}>
                                            Delete
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal show={show2} onHide={()=> setShow2(false)}>
                                        <Modal.Header closeButton>
                                        <Modal.Title>Edit the info</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            
                                            <div>
                                                date: <input onChange={(e)=> setDate(e.target.value)} type='date' />
                                            </div>
                                            <div>
                                                Number of Kayaks: <input  onChange={(e)=> setK(e.target.value)} type='number' />
                                            </div>
                                            <div>
                                                Number of Canoe: <input onChange={(e)=> setC(e.target.value)} type='number' />
                                            </div>
                                            <div>
                                                Number of Tandem: <input onChange={(e)=> setT(e.target.value)} type='number' />
                                            </div>
                                            <div>
                                                pick-up: <input onChange={(e)=> setL(e.target.value)} type='text' />
                                            </div>
                                            <div>
                                                route: <select onChange={(e)=> setR(e.target.value)} >
                                                            <option value="Sangamon">Sangamon</option>
                                                            <option value="sangHalfMorn">Sangamon Half -- morning</option>
                                                            <option value="sangHalfAft">Sangamon Half -- afternoon</option>
                                                        </select>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="secondary" onClick={()=> setShow2(false)}>
                                            Cancel
                                        </Button>
                                        <Button variant="warning" onClick={()=> updateData(data)}>
                                            Update Info
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <div>
                                    Name: {data.name}
                                    </div>
                                    <div>
                                    Number of Kayaks: {data.numOfKayaks}
                                    </div>
                                    <div>
                                    Number of Canoe: {data.numOfCanoe}
                                    </div>
                                    <div>
                                    Number of Tandem: {data.numOfTandom}
                                    </div>
                                    <div>
                                    phone: 
                                    
                                    <a href={`sms:${data.phoneNumber};?&body=Hello ${data.name}, thanks for booking. We'll see you at ${location} at ${time}.
                                    Please have everyone going sign the waiver at https://kingfisherkayaking.com/waiver.
                                    We're excited to see you soon,  - Kingfisher Kayaking`} >{data.phoneNumber}</a>
                                    
                                    </div>
                                    <div>
                                    Email: {data.email}
                                    </div>
                                    <div>
                                    time: {data.time}
                                    </div>
                                    <div>
                                    pick-up: {data.pickUpLocation}
                                    </div>
                                    <div><Button onClick={() =>setShow2(true)} style={{marginRight: '50px'}} variant="warning">Edit</Button><Button onClick={() =>setShow(true)} variant="danger" >Delete</Button></div>
                                    <br></br>
                                </div>
                            })}
                            
                        </div>
            }
            <Close value={value} />
        </div>
    )
}
