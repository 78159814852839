export default function renderTandemOption(database1, database2, value, route, startTime, databaseSaltFork) {

    let tandomAvailible = true

    if (route === 'Sangamon') {
        database1.forEach(booking => {
            if(value.isSame(booking.date, 'day')){
                if(booking.numOfTandom >= 1){
                    console.log('canoe is booked')
                    tandomAvailible = false
                } 
            }
        });
    }



    if(route === 'Sangamon (Half Route)'){


        database2.forEach(booking => {
            if(value.isSame(booking.date, 'day')){

                console.log(booking)

                if(booking.numOfTandom >= 1){
                    
                    if(startTime === booking.time){
                        tandomAvailible = false
                    } 
                }
            }
        });
    } else {
        database2.forEach(booking => {
            if(value.isSame(booking.date, 'day')){
                if(booking.numOfTandom >= 1){
                    console.log('canoe is booked')
                    tandomAvailible = false
                } 
            }
        }); 
    }

    if(route === 'Salt Fork'){


        databaseSaltFork.forEach(booking => {
            if(value.isSame(booking.date, 'day')){

    

                if(booking.numOfTandom >= 1){
                    
                    if(startTime === booking.time){
                        tandomAvailible = false
                    } 
                }
            }
        });
    } else {
        databaseSaltFork.forEach(booking => {
            if(value.isSame(booking.date, 'day')){
                if(booking.numOfTandom >= 1){
                    console.log('canoe is booked')
                    tandomAvailible = false
                } 
            }
        }); 
    }



    return tandomAvailible

}