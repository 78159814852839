import React, {useState, useEffect} from 'react';
import { Spinner, Form, Alert, Container, Button } from 'react-bootstrap';
import firebase from 'firebase';


export default function ManuelAdd({ bookingId}){


  const database = firebase.database();
  const db = firebase.firestore();
  const ref = database.ref('bookings');
  const refSaltFork = database.ref('bookings-salt-fork');
  const refSettings = database.ref('settings');


  const [routeSelected, setRoute] = useState('Both')
  const [date, setDate] = useState('')
  const [clicked, setClicked] = useState(false)
  const [phone, setPhone] = useState('no-phone')
  const [email, setEmail] = useState('no-email')
  const [name, setName] = useState('no-name')
  const [kayaks, setKayaks] = useState(0)
  const [canoe, setCanoe] = useState(0)
  const [tamdem, setTamdem] = useState(0)
  const [location, setLocation] = useState("I'll drive to the spot")

  const submitHandler = () => {

    console.log('clicked for '+ date)
    setClicked(true)
    setTimeout(function(){ setClicked(false); }, 9000);

    let min = Math.ceil(1);
    let max = Math.floor(10000);
    console.log(Math.floor(Math.random() * (max - min + 1)) + min);

    if(routeSelected === 'Sangamon 1/2 @ 9am'){
        console.log('1/2 @ 9')
        let passingData = {
            name: name,
            date: date,
            route: 'Sangamon (Half Route)',
            time: '9:00 AM',
            numOfKayaks: kayaks,
            bookingid: Math.floor(Math.random() * (max - min + 1)) + min,
            numOfCanoe: canoe,
            numOfTandom: tamdem,
            phoneNumber: phone,
            email: email,
            pickUpLocation: location,
            timeBooked: Date.now()
        }
        db.collection('sangamonHalf').add(passingData)
    } else if(routeSelected === 'Sangamon'){
        console.log('full route')
        let passingData = {
            name: name,
            date: date,
            route: 'Sangamon',
            numOfKayaks: kayaks,
            bookingid: Math.floor(Math.random() * (max - min + 1)) + min,
            numOfCanoe: canoe,
            numOfTandom: tamdem,
            phoneNumber: phone,
            email: email,
            pickUpLocation: location,
            timeBooked: Date.now()
        }
        db.collection('sangamon').add(passingData)
    } else {
        console.log('1/2 @ 2pm')
        let passingData = {
            name: name,
            date: date,
            route: 'Salt Fork',
            time: '2:00 PM',
            numOfKayaks: kayaks,
            bookingid: Math.floor(Math.random() * (max - min + 1)) + min,
            numOfCanoe: canoe,
            numOfTandom: tamdem,
            phoneNumber: phone,
            email: email,
            pickUpLocation: location,
            timeBooked: Date.now()
        }
        db.collection('sangamonHalf').add(passingData)
    }
  }


  return (
      <div >


        <Container style={{backgroundColor: 'orange'}} >

        {clicked &&
          <Alert variant='primary' >
            <div>
                <h4>Hey, you booked the following</h4>
                <span>Date:{date}</span>
                <hr></hr>
                <span>Route: {routeSelected}</span>
                <hr></hr>
                <span>Number Of Kayaks: {kayaks}</span>
                <hr></hr>
                <span>Number Of Canoes: {canoe}</span>
                <hr></hr>
                <span>Number Of Tamdem: {tamdem}</span>
            </div>
          </Alert>
        }

            <div  >
                <h4>INSTRUCTIONS:</h4>
                <ul style={{listStyle: 'inside', display: 'flex', flexDirection: 'column'}}>
                    <li>
                        For <span style={{fontWeight: 600}} >Guide</span>: use 'guide1706' as the name when you add manuelly
                    </li>
                    <li>
                        For <span style={{fontWeight: 600}} >Blocking Date</span>: max out all equipment to show avaiblity to zero for that date
                    </li>
                </ul>
            </div>
            <div><h3 style={{fontWeight: 600}} >Manuelly Add Booking</h3></div>

            <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}} >
                <div >
                    <div>
                        <span>Date</span>
                        <input type='date' onChange={(e) => setDate(e.target.value)} />
                    </div>
                    <div>
                        <span>Route</span>
                        <Form.Control onChange={(e)=> setRoute(e.target.value)} style={{width: '200px'}} as="select">
                            <option>Both</option>
                            <option >Sangamon 1/2 @ 9am</option>
                            <option >Sangamon 1/2 @ 2pm</option>
                            <option>Sangamon</option>
                        </Form.Control>
                    </div>
                    <div>
                        <span>Number Of Kayaks</span>
                        <input type='number' onChange={(e) => setKayaks(e.target.value)} />
                    </div>
                    <div>
                        <span>Number Of Canoes</span>
                        <input type='number' onChange={(e) => setCanoe(e.target.value)} />
                    </div>
                    <div>
                        <span>Number Of Tamdem</span>
                        <input type='number' onChange={(e) => setTamdem(e.target.value)} />
                    </div>
                </div>
                
                <div>
                    <div>
                        <span>Pick Up Location</span>
                        <Form.Control onChange={(e)=> setLocation(e.target.value)} style={{width: '200px'}} as="select">
                            <option>I'll drive to the spot</option>
                            <option>MarketPlace Mall</option>
                            <option >Savoy Walmart</option>
                            <option >Lincolnsquare Mall</option>
                        </Form.Control>
                    </div>
                    <div>    
                        <span>Name (Optional)</span>
                        <input onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div>    
                        <span>Email (Optional)</span>
                        <input onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div>    
                        <span>Phone (Optional)</span>
                        <input onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    {clicked
                    ? <Spinner animation="grow" variant="primary" />
                    : <div style={{marginTop: '10px'}} onClick={submitHandler} ><Button>ADD</Button></div>
                    }
                </div>
            </div>

      

        </Container>

      </div>
  )
}