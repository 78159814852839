import React from 'react'
import { Container } from 'react-bootstrap'

export default function FAQ() {
    return (
        <div className='waiver'>
            <Container  style={{backgroundColor: '#F8F9FA', padding: '20px'}} >
            <h2>Frequently Asked Questions (FAQ)</h2>
            <br></br>
            <ul>
                <li style={{fontStyle: 'italic'}} >Are life jackets (PFDs) provided?</li>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- Yes, life jackets are provided and required for each trip. We also have children (more than 30 lbs) life jackets available. You are welcome to bring your own as well.</p>
                <li style={{fontStyle: 'italic'}} >What happens if there is severe weather forecasted for my trip?</li>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- We provide the option to cancel with a <span style={{fontWeight: 600}} >full refund</span> for all trips if severe weather is forecasted on the day of your trip. We will contact you to cancel and issue a refund if storms are forecasted shortly before your trip. Please contact us if you feel weather will be an issue during your trip.</p>
                <li style={{fontStyle: 'italic'}} >Can you transport my private boat?</li>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- Yes, we provide the option of transporting your personal boat. Please call or email us to discuss rates and schedule a time to shuttle your boat.</p> 
                <li style={{fontStyle: 'italic'}} >Are pets allowed in the boats?</li>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- Yes, we allow pets in the boats. However, the owner of the pet is responsible for their safety.</p> 
                <li style={{fontStyle: 'italic'}} >This is my first time kayaking. Is it dangerous?</li>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- We recommend booking the Sangamon (Half Route) for first time kayakers. There are typically few obstacles to avoid on the river, however the river conditions are constantly changing and we will do our best to warn kayakers if the river conditions become more challenging. There may be tricky obstacles to maneuver or areas of swifter waters, particularly when the water levels are high. </p>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- All kayakers are required to wear a life jacket as capsizing and accidents occasionally occur, though the river height is typically at levels where you can stand. </p>
                <li style={{fontStyle: 'italic'}} >I still feel unsafe kayaking on the river. What else can be done to make me feel safer?</li>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- If you still feel unsafe booking a trip, you may schedule a guided trip. If there are no guided trips available for when you want to book, please contact us directly to schedule a date with one of our guides, we are very flexible. <span style={{fontWeight: 600}} >All our guides have more than 5 years of experience kayaking the East Central Illinois rivers and have passed their Paddlesports Safety Course through the American Canoe Association. </span> </p>
                <li style={{fontStyle: 'italic'}} >How many people can sit in one kayak?</li>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- Only one person may sit in the kayak. We have one 2-person tandem kayak available. We also have one canoe that can sit up to 3 people.</p>
                <li style={{fontStyle: 'italic'}} >What is the age restriction for renting a kayak?</li>
                <p style={{marginLeft:'15px', marginTop: '5px', fontSize: '15px'}} >- Kayaks rented to children 12 or younger must have a parent or guardian signature and must be always accompanied by an adult. Therefore, it is up to the parent’s or guardian’s discretion. We have had children as young as 8 years old safely kayak.</p>
          </ul>
            </Container>
        </div>
    )
}
