

// this is for loading the number on the calendar for the 1st time slot




import isBookingOnSangamonFunction from "./helpers/isBookingOnSangamon";
import isBookingOnSaltFunction from "./helpers/isBookingOnSalt";
import numberOfKayaksOnSangamonFull from './helpers/numOfKOnSangFull';
import numberOfKayaksOnSangamonHalfMorning from './helpers/numOfKOnSangHM';
import numberOfKayaksOnSangamonHalfAfternoon from './helpers/numOfKOnSangHA';
import numberOfKayaksOnSaltForkMorning from './helpers/numOfKOnSaltM';
import numberOfKayaksOnSaltForkAfternoon from './helpers/numOfKOnSaltA';

export default function renderKayaks(value, stock, booked, otherCalendarDataList, routeSelected, booked3) {
 
  //rendering nonBooked days
    let stockOfKayaks = stock

    let bookedSang = isBookingOnSangamonFunction(booked, otherCalendarDataList, value);
    let bookedSalt = isBookingOnSaltFunction(booked3, value);


    if (routeSelected === 'Salt Fork'){

        

        if(bookedSang){
            stockOfKayaks = 0
            
        } else {
            stockOfKayaks = stock -  numberOfKayaksOnSaltForkMorning(booked3, value);
        }

    }

    if(routeSelected === 'Sangamon'){

        if(bookedSalt){
            stockOfKayaks = 0
        } else {
            stockOfKayaks = stock - numberOfKayaksOnSangamonFull(booked, value) - numberOfKayaksOnSangamonHalfAfternoon(otherCalendarDataList, value) - numberOfKayaksOnSangamonHalfMorning(otherCalendarDataList, value);
        }

    }

    if(routeSelected === 'Sangamon (Half Route)'){

        if(bookedSalt){
            stockOfKayaks = 0
        } else {
            stockOfKayaks = stock - numberOfKayaksOnSangamonFull(booked, value) - numberOfKayaksOnSangamonHalfMorning(otherCalendarDataList, value);
        }
    }

    console.log(stockOfKayaks)

//   numberOfKayaksOnSangamonFull(booked, value);
//   numberOfKayaksOnSangamonHalfMorning(otherCalendarDataList, value);
//   numberOfKayaksOnSangamonHalfAfternoon(otherCalendarDataList, value);
//   numberOfKayaksOnSaltForkMorning(booked3, value);
//   numberOfKayaksOnSaltForkAfternoon(booked3, value);

  

    return stockOfKayaks
}