import React, {useRef, useEffect, useState} from 'react';
import emailjs from 'emailjs-com';
import {useHistory} from 'react-router-dom';
import firebase from 'firebase';
import { Button } from 'react-bootstrap';
import sendingData from '../SelectionForm/sendingData';

export default function Paypal( {formData, routeCost, bookingId, renderTotalPrice, canoeCost} ) {
    
    const paypal = useRef()
    //going to success page after payment
    let history = useHistory();

    function handleClick() {
      history.push("/success");
    }
    function handleErrorPage() {
        history.push("/error");
    }


    // firebase requirements
    const database = firebase.database();
    const db = firebase.firestore();


    const refSangFull = database.ref('bookings');
    const refSangHalf = database.ref('bookings-salt-fork');
    const refSaltFork = database.ref('bookings-3')
    const refSettings = database.ref('settings');
    
    const [priceTotal, setPriceTotal] = useState(0);
    const [updatingBookingId, setBookingId] = useState();
    const [updatingBookingCount, setUpdatingBookingCount] = useState(0);


    useEffect(() => {
        setBookingId(formData.bookingid);
        console.log(renderTotalPrice)
    });




    useEffect(()=> {

        let total = null
        let descr = null

        if(formData.numOfKayaks > 0 || formData.numOfCanoe > 0 || formData.numOfTandom > 0){
            if(formData.numOfKayaks === undefined){
                
                if(formData.numOfCanoe > 0){
                    total = canoeCost
                    setPriceTotal(total)
                    descr = `${formData.numOfCanoe} canoe on a route on ${formData.route}`
                }

                if(formData.numOfTandom > 0){
                    total = 45
                    setPriceTotal(total)
                    descr = `${formData.numOfTandom} tandem kayak on a route on ${formData.route}`
                }

                if((formData.numOfTandom > 0)&&(formData.numOfCanoe > 0) ){
                    total = 45 + (canoeCost)
                    setPriceTotal(total)
                    descr = `${formData.numOfTandom} tandom kayak and ${formData.numOfCanoe} canoe on a route on ${formData.route}`
                }

            } else {
                total = (formData.numOfKayaks*routeCost)+(formData.numOfCanoe * canoeCost) + (formData.numOfTandom*45)
                setPriceTotal(total)
                descr = `${formData.numOfKayaks} kayak(s) and ${formData.numOfCanoe} canoe and ${formData.numOfTandom} tandem kayak on a route on ${formData.route}`
            }
        } 

        setPriceTotal(renderTotalPrice)
        // setUpdatingBookingId(formData)
        setUpdatingBookingCount(bookingId.bookingCount)
        //sending payment through paypal

    

        window.paypal.Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent: 'CAPTURE',
                    purchase_units: [
                        {
                            description: `${descr}`,
                            amount: {
                                currency_code: 'USD',
                                value: total
                            }  
                        }
                    ]
                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture()
                console.log(order)
                sendToDatabase();
                submitAgreementHandler();
                sendEmail();
            },
            onError: (err) => {
                console.log(err)
            }
        }).render(paypal.current)
    },[])







    //send 'Agreed to Waiver to Google Drive'
    const submitAgreementHandler = async (e) => {
        try {
            const response = await fetch(`https://v1.nocodeapi.com/kingfisher/google_sheets/${process.env.REACT_APP_WAIVER_SHEET_ID}?tabId=Sheet1`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[true, formData.name, new Date().toLocaleString()]])
            });
            await response.json()
        } catch (err) {
            console.log(err)
        }
    }

    //sending email
    const sendEmail = () => {


        var templateParams = {
            date: formData.date,
            route: formData.route,
            user_name: formData.name,
            user_email: formData.email,
            bookingid: updatingBookingId,
            kayaksTaking: formData.numOfKayaks,
            tandomTaking: formData.numOfTandom,
            canoeTaking: formData.numOfCanoe,
            location: formData.pickUpLocation,
            price: renderTotalPrice,
            startTime: formData.time,
            phone: formData.phoneNumber
        };
        emailjs.send(`${process.env.REACT_APP_EMAIL_SERVICE_ID}`, `${process.env.REACT_APP_EMAIL_TEMPLATE_ID}`, templateParams, `${process.env.REACT_APP_EMAIL_USER_ID}`)
            .then((result) => {
                console.log('was emailed')
            }, (error) => {
                console.log(error.text);
            });
        history.push('/success');
    }



    //submitting form to firebase database
    const sendToDatabase = () => {

        if(formData.route === "Sangamon (Half Route)"){
            db.collection('sangamonHalf').add(formData).then(
                console.log('data was added to database')
            )
        } else if(formData.route === 'Salt Fork') {
            db.collection('saltFork').add(formData).then(
                console.log('data was added to database')
            )
        } else if (formData.route === 'Sangamon'){
            db.collection('sangamon').add(formData).then(
                console.log('data was added to database')
            )
        } else {
            console.log('error with route name')
        }

    }

    return (
        <div>
            <div  ref={paypal} ></div>
        </div>
    )
}
