export default function continueUnlessClosed(closed, value,routeSelected, history) {

    let isClosed = false
    closed.forEach(date => {
        if (value.isSame(date, 'day') ) {
            isClosed = true
        }
    });

    if(isClosed){
        alert("We are closed that date, please choose a different date");
    } else {
        history.push(`/booking/${routeSelected}/form`);
    }

}