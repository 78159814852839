import React, { useEffect} from 'react';
import SelectionFrom from '../Componets/SelectionForm/SelectionForm'


function FormPage({routeSelected, kayaks, booked, booked2, booked3, route, value, kayaksInStock,kayaksLeft, setViewing, setFormView, setFormData, bookingId, routesList , kayaksLeftOther}) {


    
    useEffect(() => {
        window.scroll(0,100)
    },[]);
    
    
    return (
        <div className='RoutesList'>
          <SelectionFrom
              kayaksLeftOther={kayaksLeftOther}
              kayaksLeftList={kayaksLeft}
              setFormView={setFormView}
              setViewing={setViewing}
              routeSelected={routeSelected}
              route={routeSelected}
              routesList={routesList}
              bookingId={bookingId}
              value={value}
              kayaks={kayaks}
              kayaksInStock={kayaksInStock}
              setFormData={setFormData}
              booked={booked}
              booked2={booked2}
              booked3={booked3}
          />
        </div>
    )
}

export default FormPage;