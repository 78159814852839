export default function numOfKayaksBooked(database, dateSelected) {

    let numBooked = 0 

    let totalArray = []


    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    function runONCEtest(){
        database.forEach(booking => {
            if((dateSelected.isSame(booking.date, 'day') && (`${booking.time}`=== '2:00 PM') )){
                let parsedNum = JSON.parse(booking.numOfKayaks) 
                totalArray.push(parsedNum)
            }
        });
    }

    runONCEtest()

    if(totalArray.length > 0){
        numBooked = totalArray.reduce(reducer)
    }




    return numBooked

}