import React from 'react';
import { Player, ControlBar } from 'video-react';

function Video(){

  
  return (
    <div>
      <div>
        <Player
          autoPlay
          muted
          loop
          src="https://firebasestorage.googleapis.com/v0/b/kingfisher-kayaking-website.appspot.com/o/kayaking.mp4?alt=media&token=1150feb3-f45f-4a73-a35b-156324760ac7"
        >
        <ControlBar disableCompletely={true}  className="my-class" />
        </Player>
      </div>
      <div className='videoTextContainer' >
        <div className='videoText'>
            Join us for a more peaceful paddling experience that will strengthen your connection to the river and its natural wonders. 
        </div>
      </div>
    </div>
  );
};

export default Video;