export default function renderCanoeOption(database1, database2, value, route, startTime, databaseSaltFork) {

    let numOfCanoe = 2

    // checking if a canoe is book on a Sangamon
    let numOfCanoeSF = 0
    let numOfCanoeSM = 0
    let numOfCanoeSA = 0
    // checking if a canoe is booked on a Salt Fork
    let numOfCanoeSFM = 0
    let numOfCanoeSFA = 0



    let totalArray = []
    let totalArray2 = []
    let totalArray3 = []
    let totalArray4 = []
    let totalArray5 = []

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    //checking sangamon full
    function runONCEtest(){
        if(value.format("dddd") === 'Saturday' || value.format("dddd") === 'Sunday'){
            database1.forEach(booking => {
                if(value.isSame(booking.date, 'day')){
                    let parsedNum = JSON.parse(booking.numOfCanoe) 
                    totalArray.push(parsedNum)
                }
            });
        }
    }

    //checking sangamon half morning
    function runONCEtest2(){
        if(value.format("dddd") === 'Saturday' || value.format("dddd") === 'Sunday'){
            database2.forEach(booking => {
                if((value.isSame(booking.date, 'day') && ((`${booking.time}`=== ('9:00 AM' || '10:00 AM' || '9am'))) )){
                    let parsedNum = JSON.parse(booking.numOfCanoe) 
                    totalArray2.push(parsedNum)
                }
            });
        }
    }

    //checking sangamon half afternoon
    function runONCEtest3(){
        if(value.format("dddd") === 'Saturday' || value.format("dddd") === 'Sunday'){
            database2.forEach(booking => {
                if(value.isSame(booking.date, 'day') && (booking.time === '2:00 PM') ){
                    // let parsedNum = JSON.parse(booking.numOfCanoe) 
                    // totalArray3.push(parsedNum)
                }
            });
        }
    }

    //checking salt fork morning
    function runONCEtest4(){
        if(value.format("dddd") === 'Saturday' || value.format("dddd") === 'Sunday'){
            databaseSaltFork.forEach(booking => {
                if(value.isSame(booking.date, 'day') && ((`${booking.time}`=== ('9:00 AM' || '10:00 AM' || '9am'))) ){
                    let parsedNum = JSON.parse(booking.numOfCanoe) 
                    totalArray4.push(parsedNum)
                }
            });
        }
    }
    
    //checking salt fork afternoon
    function runONCEtest5(){
        if(value.format("dddd") === 'Saturday' || value.format("dddd") === 'Sunday'){
            databaseSaltFork.forEach(booking => {
                if(value.isSame(booking.date, 'day') && (booking.time === '2:00 PM') ){
                    let parsedNum = JSON.parse(booking.numOfCanoe) 
                    totalArray5.push(parsedNum)
                }
            });
        }
    }


    
    runONCEtest()
    runONCEtest2()
    runONCEtest3()
    runONCEtest4()
    runONCEtest5()


    if(totalArray.length > 0){
        numOfCanoeSF = totalArray.reduce(reducer)
    }
    if(totalArray2.length > 0){
        numOfCanoeSM = totalArray2.reduce(reducer)
    }
    if(totalArray3.length > 0){
        numOfCanoeSA = totalArray3.reduce(reducer)
    }
    if(totalArray4.length > 0){
        numOfCanoeSFM = totalArray4.reduce(reducer)
    }
    if(totalArray5.length > 0){
        numOfCanoeSFA = totalArray5.reduce(reducer)
    }


    function calculating(){
        
        if(route === "Sangamon"){
            numOfCanoe = 2 - (numOfCanoeSF + numOfCanoeSM + numOfCanoeSA)
        }

        if(route === "Sangamon (Half Route)"){
            
            if(startTime === '9:00 AM'){
                numOfCanoe = 2 - (numOfCanoeSF + numOfCanoeSM)
                console.log(startTime)
            }
            if(startTime === '2:00 PM'){
                numOfCanoe = 2 - (numOfCanoeSF + numOfCanoeSA)
                console.log(startTime)
            }

        }

        if(route === 'Salt Fork'){

            if(startTime != '2:00 PM'){
                numOfCanoe = 2 - (numOfCanoeSFM)
            }
            if(startTime === '2:00 PM'){
                numOfCanoe = 2 - (numOfCanoeSFA)
            }

        }

    }


    calculating()
    
    console.log('numOfCanoe: '+numOfCanoe)





    return numOfCanoe

}