export default function sendingData(location, startTime, otherLoaction) {


    let sendingDataLocation = null
    let sendingDataStartTime = null
    if(location === 'Other (only within city limits)'){

      sendingDataLocation = otherLoaction
      if(startTime === '9:00 AM'){ sendingDataStartTime = '8:30 AM'} else { sendingDataStartTime = '1:30 PM' }

    } else if(location === 'MarketPlace Mall (Field & Streams Lot)') {

      sendingDataLocation = location
      if(startTime === '9:00 AM'){ sendingDataStartTime = '8:30 AM'} else { sendingDataStartTime = '1:30 PM' }

    } else if (location === 'LincolnSquare Mall (South Side)'){

      sendingDataLocation = location
      if(startTime === '9:00 AM'){ sendingDataStartTime = '8:30 AM'} else { sendingDataStartTime = '1:30 PM' }

    } else if (location === 'Savoy Walmart (North East Corner)'){

      sendingDataLocation = location
      if(startTime === '9:00 AM'){ sendingDataStartTime = '8:30 AM'} else { sendingDataStartTime = '1:30 PM' } 

    } else {
      sendingDataLocation = location
      sendingDataStartTime = startTime
    }




  
    return {
        location: sendingDataLocation,
        startTime: sendingDataStartTime
    }

}