import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Calendar from './calendar';
import AdminBooking from './booking';
import Staff from './staff';
import RoutesBanners from './routes&banner'

export default function Categories({passingData, setRouteSelected , waterLevelSetting, myRef, setRouteCost, routesList, setCanoeCost}) {
    


    const [turnstop, setTurnstop] = useState(1)

    let optionStyle1 = {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px',
        cursor: 'pointer',
        backgroundColor: 'green'
    }
    let optionStyle2 = {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px',
        cursor: 'pointer',
        backgroundColor: '#56899e'
    }
    let optionStyle3 = {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px',
        cursor: 'pointer',
        backgroundColor: '#b5911c'
    }
    let optionStyle4 = {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px',
        cursor: 'pointer',
        backgroundColor: '#c22f15'
    }

    let fontStyle={
        color: 'white'
    }

    let renderAdmin = null
    if(turnstop === 1){
        renderAdmin = (
            <>
                <div style={optionStyle2} onClick={() => setTurnstop(2)} ><h2 style={fontStyle} >Calendar <FontAwesomeIcon icon="info-circle"  size="1x" /></h2></div>
                <div style={optionStyle1} onClick={() => setTurnstop(3)} ><h2 style={fontStyle}>Bookings <FontAwesomeIcon icon="cog"  size="1x" /></h2></div>
                <div style={optionStyle3} onClick={() => setTurnstop(4)} ><h2 style={fontStyle}>Clock in/out <FontAwesomeIcon icon="clock"  size="1x" /></h2></div>
                <div style={optionStyle4} onClick={() => setTurnstop(5)} ><h2 style={fontStyle}>Routes & Banner Editor<FontAwesomeIcon icon="cog"  size="1x" /></h2></div>
            </>
        )
    } else if (turnstop === 2){
        renderAdmin = <Calendar setTurnstop={setTurnstop} passingData={passingData}  />
    } else if (turnstop === 3){
        renderAdmin = <AdminBooking setTurnstop={setTurnstop} passingData={passingData} />
    } else if (turnstop === 4) {
        renderAdmin = (
            <Staff setTurnstop={setTurnstop} passingData={passingData} />
        )
    } else if (turnstop ===5){
        renderAdmin = (
            <RoutesBanners 
            setRouteSelected={setRouteSelected}
            waterLevelSetting={waterLevelSetting}
            myRef={myRef}
            setRouteCost={setRouteCost}
            routesList={routesList}
            setCanoeCost={setCanoeCost}
            />
        )
    }

    return (
        <div>
            {renderAdmin}
        </div>
    )
}
