import React, {useState, useEffect} from 'react';
import './calendar.css';
import buildCalendar from './build';
import renderKayaks from './renderKayaks';
import renderKayaksOther from './renderKayaksOtherList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'font-awesome/css/font-awesome.min.css';
import Weather from '../weather/weather';
import {Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import AdminCal from './adminCal/adminCal';
import ContinueUnlessClosed from './dayStyleHelpers/continueUnlessClosed';
import IsGuided from './dayStyleHelpers/isGuided';
import ZeroKayaksLeft from './dayStyleHelpers/zeroKayaksLeft';
import TwoKayaksLeft from './dayStyleHelpers/twoKayaksLeft';
import FourKayaksLeft from './dayStyleHelpers/fourKayaksLeft';
import SixKayaksLeft from './dayStyleHelpers/sixKayaksLeft';
import SaltForkM from './helpers/numOfKOnSaltM'

export default function Calendar({value, setRouteSelected, onChange,closed, otherCalendarDataList, booked, kayaksInStock , routeSelected, setViewing, kayaksLeft, setKayaksLeft, setKayaksLeftOther, kayaksLeftOther, booked3}) {
    const [calendar , setCalendar] = useState([]);
    const [guidedRoute, setGuideRoute] = useState('no guide');
    const [displayingDataState, setDisplayingDataState] = useState([]);
    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        setCalendar(buildCalendar(value));
        setKayaksLeft(renderKayaks(value, kayaksInStock, booked, otherCalendarDataList, routeSelected, booked3));
        setKayaksLeftOther(renderKayaksOther(value, kayaksInStock, booked, otherCalendarDataList, routeSelected, booked3));

        // rendering 'Guide on: {guideRoute}'
        setGuideRoute('no guided trip')
        booked.forEach(bookingData => {
            if ((bookingData.name === 'guide1706') && value.isSame(bookingData.date, 'day')){
                setGuideRoute(`${bookingData.route} guided`)
            }
        });

    }, [value])

    useEffect(()=> {

        // if page reloads, code below resets the route to what was selected in the url
        if('/admin' != location.pathname){
            if('/booking/Sangamon (Half Route)' === location.pathname){setRouteSelected(location.pathname.slice(location.pathname.indexOf('g/') + 2))
            } else {setRouteSelected(location.pathname.slice(location.pathname.indexOf('g/') + 2))}
        }
    },[])

    //styling
    function isSelected(day, value) {
        return value.isSame(day, 'day');
    }
    function beforeToday(day) {
        return day.isBefore(new Date(), 'day');
    }
    function isToday(day) {
        return day.isSame(new Date(), 'day');
    }


    function isClosed(day) {
        let classes = null

        closed.forEach(date => {
            if (day.isSame(date, 'day')) {
                    classes = date
            }
        })
        return day.isSame(classes, 'day')
    }


    function kayaksleftStyling(day, database, kayaksInStock) {
        if (ZeroKayaksLeft(day, database, kayaksInStock)) return 'kayaks-0'
        if (TwoKayaksLeft(day,  database, kayaksInStock)) return 'kayaks-2'
        if (FourKayaksLeft(day, database, kayaksInStock)) return 'kayaks-4'
        if (SixKayaksLeft(day, database, kayaksInStock,)) return 'kayaks-6'
    }

    function dayStyles(day, value) {
        if (beforeToday(day)) return 'before'
        if (isSelected(day, value)) return 'red'
        if (isToday(day)) return 'today'

        if (routeSelected === 'Salt Fork') {
            SaltForkM(booked3, value)
        } else {
            kayaksleftStyling(day, booked, kayaksInStock)
        }
    }


    //making the guide change colors for it to pop
    const [guideClass,setGuideClass] = useState(true);
    let renderedGuideClass = 'guide-yes'
    if(guideClass){
        setTimeout(function(){ setGuideClass(false) }, 2000);
        renderedGuideClass = 'guide-yes'
    } else {
        renderedGuideClass = 'guide-yes color-change'
        setTimeout(function(){ setGuideClass(true) }, 300);
    }

    function guideStyle(day, value) {
        if (IsGuided(day, booked, kayaksInStock)) return renderedGuideClass
        if (beforeToday(day)) return 'guide-no'
        return 'guide-no'
    }
    function guideStyleForDay(day, value) {
        if (IsGuided(day, booked, kayaksInStock)) return 'guide-no'
        return 'guide-yes'
    }
    function closedStyle(day, value) {
        if ((isClosed(day)) && !(beforeToday(day))) return 'closedSymbol'
        return 'no-closedSymbol'
    }





    return ( 
        <div className='full-calendar' >
            <div className='top-half-calendar' >
                <div className='selection-footer ' >
                    <div className='info-text-header' >
                        <h>Pick A Day</h>
                    </div>
                    <div className='parent' >
                        <div className='info-text-header-underline' >o</div>
                    </div>
                    <div className='info-text-header-2' >
                        <div>
                            <h><FontAwesomeIcon icon="map-marker-alt"  size="1x" /> {routeSelected}</h>
                        </div>
                        <div>
                            <h><FontAwesomeIcon icon="calendar-alt"  size="1x" /> Date: {value.format("MM/DD")}</h>
                        </div>
                    </div>
                    <div className='info-text' >

                        
                        {/* {routeSelected === 'Sangamon'
                            ? <h6 className='cal-b-text' >Available: <span className='num-of-kayaks-on-that-day' >{kayaksLeft}</span> kayaks</h6>
                            : <div>
                            <h6>*On Weekdays: afternoon time is 12pm. NOT 2pm</h6>
                            <h6 className='cal-b-text' >9am Available: <span className='num-of-kayaks-on-that-day' >{kayaksLeft}</span> kayaks</h6>
                            <h6 className='cal-b-text' >*2pm Available: <span className='num-of-kayaks-on-that-day' >{kayaksLeftOther}</span> kayaks</h6>
                            </div>
                        } */}
                        <div className='legend' >
                        <div className='avalible' >Available</div>
                        <ul>
                            <li className='kayaks-8 l-item' ></li>
                            <li className='kayaks-6 l-item' ></li>
                            <li className='kayaks-4 l-item' ></li>
                            <li className='kayaks-2 l-item' ></li>
                            <li className='kayaks-0 l-item' ></li>
                        </ul>
                        <div className='unavalible'>Not Available</div>
                    </div>
                    </div>

                    <div >
                        <h6 className='cal-b-text guide' ><FontAwesomeIcon icon="compass" size="1x" /> Guided Trip: {guidedRoute} </h6>
                        <h6 className='cal-b-text guide'  ><span style={{marginBottom: '10px'}} className='closedSymbol'  >X</span> Closed: Limited Staff / Bad Weather </h6>
                        <Weather value={value} />
                        <div className='direction-buttons' >

                            {((kayaksLeft + kayaksLeftOther > 0)) &&
                                <div className='arrow'  >
                                    <Link onClick={() => ContinueUnlessClosed(closed, value,routeSelected, history)} className=' no-hover' >
                                        Book <FontAwesomeIcon icon="arrow-right"  size="1x" />
                                    </Link>
                                </div>
                            }

                        <AdminCal otherCalendarDataList={otherCalendarDataList} value={value} booked={booked} booked3={booked3} routeSelected={routeSelected} />
                    </div>
                    </div>
                </div>
                <div className='calendar'>
                    <div className='header'>
                        <div className='previous' onClick={() => !(value.isSame(new Date(), 'month')) && onChange(value.clone().subtract(1, 'month'))}>
                        {value.isSame(new Date(), 'month') ? <FontAwesomeIcon icon="arrow-left" size="1x" /> : null}
                        </div>
                        <div className='current'>
                            {value.format('MMMM')} {value.format('YYYY')}
                        </div>
                        <div className='next' onClick={() => {onChange(value.clone().add(1, 'month'))}}><FontAwesomeIcon icon="arrow-right"  size="1x" /></div>
                    </div>
                    <div className='body'>
                        <div className="day-names">
                            {
                                ["s","m","t","w","t","f","s"].map(d => <div className='week'>{d}</div>)
                            }
                        </div>
                        {calendar.map((week) => (
                            <div>
                                {week.map((day) => (
                                    <div  className='day' id={day.format("D")} key={day} 
                                    onClick={() => { !beforeToday(day) && onChange(day)
                                        }}>
                                        <div className={dayStyles(day, value)}>
                                            <span className={guideStyleForDay(day, value)} >{day.format('D').toString()}</span>
                                            <FontAwesomeIcon icon="compass"  className={guideStyle(day, value)}  size="2x" />
                                            <span className={closedStyle(day, value)} >X</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            </div>
    );
}