import React, {useState, useEffect} from 'react';
import '../../App.css';
import { Col, Container, Row, Image, Carousel, Card, Navbar, Table, Nav, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, useLocation, useHistory} from 'react-router-dom';
import '../../Componets/small-componets/Navbar/navbar.css'

export default function AboutTheRiver() {


    const [waterLevel, setWaterLevel] = useState('loading...')

    //getting waterlevel data
    const url = 'https://waterservices.usgs.gov/nwis/iv/?format=json&sites=05570910&parameterCd=00065&siteStatus=all'

    useEffect(() => {
        async function getData() {
            const response = await fetch(url);
            const data = await response.json();
            const waterLevelStat = data.value.timeSeries[0].values[0].value[0].value
            setWaterLevel(waterLevelStat)
        }
        getData();
    },[])







    return (
        <div className='waiver'>
            <Container className='waiver-container-rivers' >
                <Navbar bg="light" variant="light">
                    {/* <Navbar.Brand href="#home">Navbar</Navbar.Brand> */}
                    <Nav className="mr-auto">
                        <Link className='link' active to='/about'>Trip Info</Link>
                        <Link className='link' to='/aboutriver'>About The River</Link>
                        <Link className='link' to='/wildlife'>Wildlife</Link>
                        <Link className='link' to='/safety'>Safety</Link>
                    </Nav>
                </Navbar>

                <br></br>
                <Row>
                    <Col   >
                    <div>
                        <h1>The Sangamon River</h1>
                    </div>
                    <div>
                        <hr></hr>
                        <h4>Introduction</h4>
                        <ul>
                            <li>The Sangamon River meanders across Central Illinois for close to 250 miles</li>
                            <li>Its headwaters begin about 30 miles northwest of Mahomet near Ellsworth, flowing southwest through Decatur, Springfield, and eventually emptying into the Illinois River near Beardstown.</li>
                        </ul>
                        <h4>History</h4>
                        <ul>
                            <li>The name of the river comes from the Pottawatomie Sain-guee-mon, which translates to “where there is plenty to eat”.  Several other derivatives exist including from the ‘Saukie’ Native American  tribe and ‘ong’ combining into “Saukie-ong” or river of the Sauks.</li>
                            <li>In 1830, The 21-year-old Abraham Lincoln’s family built a cabin and  settled on the Sangamon River in Macon County. Lincoln was an avid floater of the Sangamon, even building a flatboat and traveling all the way to the Illinois River, then down the Mississippi River to New Orleans. He even advocated for improving the navigability of the river in his very first political announcement in 1832.</li>
                            <li>The river has been home to several other Native American tribes before European settlement, including a conflict between the Illini and Fox as part of the French and Iroquois Wars.</li>
                            <li>The Sangamon was untapped of its natural resources until the Civil War era. The inventions of the steel plow, field drain tile, and the expansion of the railroad created the means of commercially farming the region. </li>
                        </ul>
                        <h4>Geology & Ecology</h4>
                        <ul>
                            <li>Much of the Upper Sangamon River (from its headwaters to Decatur) retains  its original form  from the days before European settlement, while the Lower Sangamon has been dredged and channelized over the years. </li>
                            <li>The Upper Sangamon River Basin (the area of land drained by the Sangamon) covers an area of approximately 2400 km². Pre-settlement estimates 90% of the basin to be prairie and 10% forest with no original prairie surviving outside of a small remnant discovered along railroad tracks and only 850 acres of old forest surviving. However, there remains 11 times the high quality forest in the basin than Illinois as a whole, much of it in Allerton Park. </li>
                            <li>Despite environmental modification, there’s an abundance of native species that include: 925 plants, 71 species of fish, 34 species of mussels, 44 species of mammals, 29 species of amphibians and reptiles, and 273 species of migrating or nesting birds.</li>
                            <li>During the last glaciation 28,000 to 12,000 BP, the ice of the Lake Michigan ice sheet deposited a terminal moraine (mass of rocks and sediment carried by the farthest advancement of the glacier) parallel to the Sangamon River. The ice was forced to melt behind the terminal moraine, creating several short creeks on the eastern side of the Upper Sangamon River that drain the face of the moraine. </li>
                            <li>Four creeks have been recognized as “Biologically Significant Streams” including the Sangamon itself, Lone Tree Creek, Drummer Creek, and Goose Creek.</li>
                        </ul>
                        <h4>Modern Day</h4>
                        <ul>
                            <li>The water quality upstream of Lake Decatur is generally good despite the high level of agricultural runoff</li>
                            <li>Several measures can be taken to improve the ecological conditions of the river including implementing sustainable farming practices (no till, add conservation buffer, spray less toxic pesticides), the restoration of prairie and wetlands, improved erosion control, redistributing drainage areas, and removal of invasive plant and animal species.</li>
                        </ul>
                    </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}
