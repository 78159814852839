import React, {useState, useEffect, useRef} from 'react';
import './NewApp.css';

//pages
import About from './pages/about/about';
import Wildlife from './pages/about/wildlife';
import Safety from './pages/about/safety';
import HomePage from './pages/home';
import BookingPage from './pages/booking';
import SuccessPage from './pages/success';
import FormPage from './pages/formPage';
import WaiverPage from './pages/signWaiverPage';
import CancelIndex from './pages/cancel/index';
import ErrorPage from './pages/errorPage';
import BlockBookingPage from './pages/blockBookingPage';
import Involved from  './pages/involved';
import Donate from './pages/donate';
import Admin from './pages/admin/admin';
import FAQ from './pages/faq';
import AboutTheRiver from './pages/about/aboutTheRiver';

import PayForm from './Componets/Paypal/payForm';

//lib
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import moment from 'moment';
import firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css'; // import css


//Awesome Fonts
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faArrowLeft, faArrowRight, faCloudRain, faCloud, faSun, faMeh, faSmile, faWater, faClock, faDollarSign, faPlus, faBan , faMapMarkerAlt, faCompass, faCalendarAlt,faStoreAltSlash, faExclamationCircle, faSnowflake, faCarSide , faSeedling, faFish, faAppleAlt, faDrumstickBite, faBug, faViruses, faUserLock, faMapMarkedAlt, faCog, faInfoCircle,faBrush, faUser } from '@fortawesome/free-solid-svg-icons'
import { Image, ProgressBar} from 'react-bootstrap';


//components
import MyNavbar from './Componets/small-componets/Navbar/navbar';
import Footer from './Componets/small-componets/footer/footer';
import LogoHead from './logo-head.png'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

export default function App(){

  //Awesome Fonts function
  library.add( faCheckSquare,faArrowLeft, faArrowRight, faCloudRain, faCloud, faSun, faMeh, faSmile, faWater, faClock,faDollarSign, faPlus, faBan  , faMapMarkerAlt, faCompass, faCalendarAlt,faStoreAltSlash, faExclamationCircle, faSnowflake, faCarSide , faSeedling, faFish, faAppleAlt, faDrumstickBite, faBug, faViruses, faUserLock, faMapMarkedAlt, faCog, faInfoCircle,faBrush, faUser );

  // firebase requirements
  const database = firebase.database();
  const db = firebase.firestore();

  const ref = database.ref('bookings');
  const ref2 = database.ref('bookings-salt-fork');
  const ref3 = database.ref('bookings-3');
  const refSettings = database.ref('settings');
  const refRoutes = database.ref('routes');

  // Use State AREA
  const [value , setValue] = useState(moment());
  const [routeSelected, setRouteSelected] = useState('no route')
  const [kayakStock, setkayakStock] = useState()
  const [formData, setFormData] = useState();
  const [waterLevelSetting, setWaterLevelSetting] = useState(1)
  const [routesList, setRoutes] = useState([]);
  const [routeCost, setRouteCost] = useState(0);
  const [canoeCost, setCanoeCost] = useState(0);
  const [bookingId, setBookingId] = useState(0);
  const [blockBooking, setBlockBooking] = useState(false)
  // booked dates state for calendar
  const booked = useState({bookings: []})
  const booked2 = useState({bookings2: []})
  const booked3 = useState({bookings3: []})
  const [kayaksLeft, setKayaksLeft] = useState();
  const [kayaksLeftOther, setKayaksLeftOther] = useState();
  
  const [newBooking, setNewBooking] = useState([])
  const [newBooking2, setNewBooking2] = useState([])
  const [newBooking3, setNewBooking3] = useState([])
  const [datesClosed, setClosedDates] = useState([])

  //change this before lauching to web
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0)

  // changing the banner to update customers
  const [bannerColor, setBannerColor] = useState('blue')
  const [hide, setBanner] =useState(false)
  const [bannerText, setBannerText] = useState('Closed')

  //rendering the data from the firebase for the calendar & rendering settings
  useEffect(() => {
    // ref.on('value', gotDataHandler, errDataHandler);
    // ref2.on('value', gotDataHandler2, errDataHandler);
    // ref3.on('value', gotDataHandler3, errDataHandler);
    refSettings.on('value', gotSettingsHandler, errDataHandler)
    // refRoutes.on('value', gotRouteDataHandler, errDataHandler);

    getFirebaseData()
  },[db])




  //calling routes list + details
  // const gotRouteDataHandler = (data) => {
  //   const dataRoutes = data.val();
  //   const keys = Object.keys(dataRoutes);
  //   for (let i = 0; i < keys.length; i ++){
  //     let k = keys[i]
  //     const list = dataRoutes[k]
  //     setRoutes(routesList => [...routesList, list])
  //   }
  // }
  const gotSettingsHandler = (data) => {
    const dataSettings = data.val()
    setkayakStock(dataSettings.kayakStock)
    setWaterLevelSetting(dataSettings.waterLevelLimit)
    setBookingId(dataSettings)
    setBlockBooking(dataSettings.blockBooking)
  }
  const errDataHandler = (err) => {
    console.log('Error!')
    console.log(err)
  }

 

  const getFirebaseData = () => {
    db.collection('banner').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setBanner(doc.data().hide)
        setBannerColor(doc.data().color)
        setBannerText(doc.data().text)
      })
    })
    db.collection('routes').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {

        let addingObject = {
            data: doc.data(),
            id: doc.id
        }

        setRoutes(routesList => [...routesList, addingObject])
      })
    })
    db.collection("sangamon").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

            const dataObject = {
              date: doc.data().date,
              numOfCanoe: doc.data().numOfCanoe, 
              numOfKayaks: doc.data().numOfKayaks, 
              name: doc.data().name, 
              route: doc.data().route, 
              bookingid: doc.data().bookingid, 
              key: doc.id, 
              timeBooked: doc.data().timeBooked, 
              email: doc.data().email, 
              time: doc.data().time,
              pickUpLocation: doc.data().pickUpLocation,
              phoneNumber: doc.data().phoneNumber,
              numOfTandom: doc.data().numOfTandom, 
              database: {id: doc.id, ref: 'sangamon'}
            }

            setNewBooking(newBooking => [...newBooking, dataObject]);
        });
    });
    db.collection("sangamonHalf").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          const dataObject = {
            date: doc.data().date,
            numOfCanoe: doc.data().numOfCanoe, 
            numOfKayaks: doc.data().numOfKayaks, 
            name: doc.data().name, 
            route: doc.data().route, 
            bookingid: doc.data().bookingid, 
            key: doc.id, 
            timeBooked: doc.data().timeBooked, 
            email: doc.data().email, 
            time: doc.data().time,
            pickUpLocation: doc.data().pickUpLocation,
            phoneNumber: doc.data().phoneNumber,
            numOfTandom: doc.data().numOfTandom, 
            database: {id: doc.id, ref: 'sangamonHalf'}
          }

            setNewBooking2(newBooking2 => [...newBooking2, dataObject]);
            setLoading(true)
        });
    });
    db.collection("saltFork").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {


          const dataObject = {
            date: doc.data().date,
            numOfCanoe: doc.data().numOfCanoe, 
            numOfKayaks: doc.data().numOfKayaks, 
            name: doc.data().name, 
            route: doc.data().route, 
            bookingid: doc.data().bookingid, 
            key: doc.id, 
            timeBooked: doc.data().timeBooked, 
            email: doc.data().email, 
            time: doc.data().time,
            pickUpLocation: doc.data().pickUpLocation,
            phoneNumber: doc.data().phoneNumber,
            numOfTandom: doc.data().numOfTandom, 
            database: {id: doc.id, ref: 'saltFork'}
          }



            setNewBooking3(newBooking3 => [...newBooking3, dataObject]);
        });
    });
    db.collection('closed').get().then((querySnapshot) => {

      querySnapshot.forEach((doc) => {
        const a = doc.data().date
        setClosedDates(datesClosed => [...datesClosed, a])
      })
    })
  }

  

  //nav scroll for booking 
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  let renderBooking = null
  if (blockBooking) {
    renderBooking = <BlockBookingPage/>
  } else {
    renderBooking = (
      <FormPage  
                    routeSelected={routeSelected}
                    value={value}
                    routesList={routesList}
                    kayaksInStock={kayakStock}
                    kayaks={newBooking}
                    booked={newBooking}
                    booked2={newBooking2}
                    booked3={newBooking3}
                    formData={formData}
                    setFormData={setFormData}
                    bookingId={bookingId}
                    kayaksLeft={kayaksLeft}
                    kayaksLeftOther={kayaksLeftOther}
                    />
    )
  }



  
  useEffect(() => {
    for (let index = 1; index < 101; index++) { 
      setCount(index) 
    }
  }, [])




  //whats rendered to the DOM
  return (
    <>
      {loading
        ? 
        
        <Router>
        <div >
            {hide &&
              <div style={{fontSize: '20px', color: 'white', backgroundColor: `${bannerColor}`, fontWeight: 600, textAlign: 'center', padding: '10px'}} >
                {bannerText}
              </div>
            }
            <MyNavbar executeScroll={executeScroll} />
            <Switch>
            <Route path='/about' render={()=> <About
              bookingId={bookingId}

            />} />
            <Route path='/wildlife' render={()=> <Wildlife />} />
            <Route path='/safety' render={()=> <Safety />} />
            <Route path='/faq' render={()=> <FAQ />} />
            <Route path='/aboutriver' render={()=> <AboutTheRiver/>}/>
            <Route path='/booking/:id/form/pay' render={() =>         
              <div className='RoutesList'>
                  <PayForm

                      route={routeSelected}
                      value={value}
                      canoeCost={canoeCost}
                      formData={formData}
                      routeCost={routeCost}
                      bookingId={bookingId}
                  />
              </div>}/>
            <Route path='/booking/:id/form' render={() => renderBooking}/>
            <Route path='/booking/:id' render={() => <BookingPage  
                routeSelected={routeSelected}
                kayaksLeft={kayaksLeft}
                kayaksLeftOther={kayaksLeftOther}
                setKayaksLeft={setKayaksLeft}
                setKayaksLeftOther={setKayaksLeftOther}
                value={value}
                setValue={setValue}
                booked={newBooking}
                booked2={newBooking2}
                booked3={newBooking3}
                kayaksInStock={kayakStock}
                kayaks={booked[0].bookings}
                closed={datesClosed}
                setRouteSelected={setRouteSelected}
                />}/>
            <Route path='/success' exact render={() => <SuccessPage formData={formData} />} />
            <Route path='/cancel-trip' exact render={() => <CancelIndex booked={booked[0].bookings} />} />
            <Route path='/error' exact render={() => <ErrorPage  />} />
            <Route path='/waiver' exact render={() => <WaiverPage />} />
            <Route path='/involved' exact render={() => <Involved />} />
            <Route path='/donate' exact render={() => <Donate />} />
            <Route path='/admin' exact render={() => <Admin
                routeSelected={routeSelected}
                kayaksLeft={kayaksLeft}
                kayaksLeftOther={kayaksLeftOther}
                setKayaksLeft={setKayaksLeft}
                setKayaksLeftOther={setKayaksLeftOther}
                value={value}
                setValue={setValue}
                booked={newBooking}
                booked2={newBooking2}
                booked3={newBooking3}
                kayaksInStock={kayakStock}
                closed={datesClosed}

                setRouteSelected={setRouteSelected} 
                waterLevelSetting={waterLevelSetting}
                routesList={routesList}
                setRouteCost={setRouteCost}
                setCanoeCost={setCanoeCost}
            />} />
            <Route path='/' exact render={() => <HomePage
                myRef={myRef} 
                setRouteSelected={setRouteSelected} 
                waterLevelSetting={waterLevelSetting}
                routesList={routesList}
                setRouteCost={setRouteCost}
                setCanoeCost={setCanoeCost}
                />} />
            </Switch>

            <Footer/>
        </div>
      </Router>


        : <div style={{ display: 'flex', justifyContent: 'center'}} >
              <div  >
                <div style={{paddingTop: '100px'}} >
                  <Image width='150px' src={LogoHead} fluid />
                </div>
                <div>
                  <ProgressBar now={count} />
                </div>
              </div>
          </div>
      }
    </>  
  )
}