import moment from "moment"
export default function isGuided(day, booked,kayaksInStock) {

    let classes = null
    let kayakCount = []

    booked.forEach(bookedData => {
        if (day.isSame(bookedData.date, 'day')) {
            let parsedKayaks = JSON.parse(bookedData.numOfKayaks)
            kayakCount.push(parsedKayaks)

            if (kayakCount.length > 0){
                if (bookedData.name === 'guide1706'){
                    if (moment(bookedData.date).isAfter(new Date(), 'day')){
                        classes = bookedData.date
                    }
                }
            }
        }
    })
    return day.isSame(classes, 'day')
}