import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase';
import moment from 'moment';
import { Button,  Modal, Spinner } from 'react-bootstrap';
import Clock from './clock'

export default function Staff({setTurnstop}) {

    const storage = firebase.storage();
    const db = firebase.firestore();

    const [staffList, setStaffList] = useState([])
    const [working, setWorking] = useState([])
    const [personWorking, setPersonWorking] = useState('none rn')
    const [show, setShow] = useState(false);
    const [info, setInfo] = useState('');
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getStaffList()
        workingList()
    }, [db])

 


    const getStaffList = () => {
        setStaffList([])

        db.collection("staff").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setStaffList(staffList => [...staffList, doc.data()]);
            });
        });

    }

    const workingList = () => {
        db.collection("working").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {

                let recievingObject = {
                    data: doc.data(),
                    id: doc.id
                }

                setWorking(working => [...working, recievingObject]);
            });
        });
    } 

    const clockIn = () => {
        let startTime = moment().format('H:mm:ss')

        let formData = {
            name: personWorking,
            start: startTime,
            isWorking: true
        }

        if(personWorking === 'none rn'){
            alert(`Please Select Who's Working!! `);
        } else {
            db.collection('working').add(formData).then(
                console.log('data was added to database')
            )
            console.log(startTime)
            console.log(personWorking)
        }
        setWorking([])
        workingList()

    }
  
    const sendToGoogleSheets = async (data) => {
        try {
            const response = await fetch(`https://v1.nocodeapi.com/kingfisher/google_sheets/hNLYEjnESYFsxHFt?tabId=Sheet1`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[data.name, data.date, data.start, data.end, data.info]])
            });
            await response.json()

            console.log('sent')
            delateWorking(data.id)
        } catch (err) {
            console.log(err)
        }
    }


    const clockOut = (data) => {

        setLoading(true)

        let sendingInfo = {
            start: data.data.start,
            end: moment().format('H:mm:ss'),
            name: data.data.name,
            date: moment().format('MM/DD/YYYY'),
            id: data.id,
            info: info
        }

        sendToGoogleSheets(sendingInfo)

    }


    const delateWorking = (id) => {
        console.log(id)
        console.log('deleting....')
        db.collection('working').doc(`${id}`).delete().then(() => {
            setWorking([])
            workingList()
            setShow(false)
            setLoading(false)
        }).catch((error) => {
            alert('error, let James know you got an error')
        });
    }


    return (
            <div>
                <div style={{display: 'flex', justifyContent: 'center', margin: '10px'}} >
                    <FontAwesomeIcon onClick={()=>setTurnstop(1)} icon="arrow-left"  size="2x" style={{marginRight: '10px'}} />
                    <h3>Clock in/out</h3>
                </div>
                <Clock/>
                <div style={{display: 'flex', justifyContent: 'center', backgroundColor: 'orange', flexDirection: 'column'}} >
                    <div  style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'}}>
                        <h2 style={{marginTop: '30px'}} >CLOCK IN <FontAwesomeIcon icon="clock"  size="1x" /></h2>
                        <div style={{display: 'flex', justifyContent: 'center'}} > 
                            <select style={{borderRadius: '7px', height: '38px', marginRight: '10px'}} onChange={ (e) => setPersonWorking(e.target.value)} >
                                {staffList.map(data => {
                                    return <option>{data.name}</option>
                                })}
                                <option value='none rn' >----</option>
                            </select>
                            <div style={{marginBottom: '30px'}} >
                                <Button onClick={clockIn} >Clock In</Button>
                            </div>
                        </div>
                    </div>

                    <div style={{backgroundColor: '#0069D9', color: 'white'}} >
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'}}>
                            <h2 style={{color:'white', marginTop: '20px'}} >CLOCK OUT <FontAwesomeIcon icon="clock"  size="1x" /></h2>
                            {working.map(data => {
                                return (
                                <div>
                                    <div style={{marginBottom: '30px', display: 'flex', justifyContent:'center'}} >
                                        <div style={{margin: '10px'}}>
                                            {data.data.name}
                                        </div>
                                        <div>
                                            <Button variant="warning" onClick={()=> setShow(true)} >Clock Out</Button>
                                        </div>
                                    </div>
                                    <Modal show={show} onHide={()=> setShow(false)}>
                                        <Modal.Header closeButton>
                                        <Modal.Title>Work Report</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                Info On How it Went
                                            </div>
                                            <div>
                                                <textarea onChange={(e)=> setInfo(e.target.value)} name="paragraph_text" cols="40" rows="10"></textarea>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="secondary" onClick={()=> setShow(false)}>
                                            Cancel
                                        </Button>


                                        {loading
                                            ? <Spinner animation="border" />
                                            : <Button variant="success" onClick={() =>clockOut(data)}>Clock Out</Button>
                                        }

                                        </Modal.Footer>
                                    </Modal>
                                </div>)
                            })}
                        </div>
                    </div>   
                </div>
            </div>
    )
}
