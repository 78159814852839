import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ManuelAdd from './manuelAdd';
import SearchDatabase from './searchDatabase';

export default function AdminBooking({setTurnstop, passingData}) {
    return (
        <div style={{backgroundColor: 'green', paddingTop: '50px', paddingBottom: '50px'}} >
            <div style={{display: 'flex', justifyContent: 'center', color: 'white'}} >
                <FontAwesomeIcon onClick={()=>setTurnstop(1)} icon="arrow-left"  size="2x" style={{marginRight: '10px'}} />
                <h3>Bookings</h3>
            </div>

            <div>
                <ManuelAdd  bookingId={passingData.bookingId}/>
            </div>

            <div style={{backgroundColor: 'white', marginTop: '30px'}} >
                <SearchDatabase booked={passingData.booked} booked2={passingData.booked2}  />
            </div>

        </div>
    )
}
