export default function isBookingOnSalt(booked3, value) {

    let isTrue = false    

    booked3.forEach(booking => {
        if (value.isSame(booking.date, 'day') ) {
            isTrue = true
        }
    });



    return isTrue

}